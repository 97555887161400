import { routeCheck } from "../helpers/util";
import LocalStorageService, {
  LocalStorageServiceClass,
} from "./LocalStorageService";
import LocalStorageServiceCX from "./LocalStorageServiceCX";
import LocalStorageServiceHR from "./LocalStorageServiceHR";
import LocalStorageServiceMember from "./LocalStorageServiceMember";
import LocalStorageServiceBR from "@/core/services/LocalStorageServiceBR";

export default function getLocalStorageService():
  | LocalStorageServiceClass
  | undefined {
  if (routeCheck.isCX()) {
    return LocalStorageServiceCX;
  } else if (routeCheck.isMB()) {
    return LocalStorageServiceMember;
  } else if (routeCheck.isFD()) {
    return LocalStorageService;
  } else if (routeCheck.isHR()) {
    return LocalStorageServiceHR;
  } else if (routeCheck.isBR()) {
    return LocalStorageServiceBR;
  }
}
