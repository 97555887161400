/* eslint-disable @typescript-eslint/no-explicit-any */
import { defineStore } from "pinia";
import { BrokerApi, FDSearchMemberResponseItem } from "@/api";
import { openModalById, pluralNormalizer } from "@/core/helpers/custom";
import router from "@/router";
import { brRouteName } from "@/core/helpers/util";

interface IAmountTerminateMember {
  effectiveDate: Date | undefined;
  memberId: number | undefined;
}

export const useBrTerminateMember = defineStore({
  id: "brTerminateMember",
  state: () => ({
    id: undefined as number | undefined,
    selectedMemberList: [] as (FDSearchMemberResponseItem & {
      terminateEffectiveDate?: Date | undefined;
    })[],
    terminateMember: {
      amountTerminateMembers: <IAmountTerminateMember[]>[],
    },
    amount: undefined as number | undefined,
  }),
  getters: {
    isAllSelectedMemberHasTerminateDate(): boolean {
      return (
        this.terminateMember.amountTerminateMembers.filter(
          (item) => item.effectiveDate === undefined
        ).length === 0
      );
    },
    getAmountTerminateMember(): IAmountTerminateMember[] {
      return this.terminateMember.amountTerminateMembers;
    },
    getAmountString(): string {
      return `USD ${this.amount || 0}`;
    },
    getTotalTerminateMemberString(): string {
      return `${this.selectedMemberList.length} `;
    },
    isNoMemberSelected(): boolean {
      return this.selectedMemberList.length < 1;
    },
  },
  actions: {
    setSelectedMemberList(selectedMemberList: FDSearchMemberResponseItem[]) {
      this.selectedMemberList = selectedMemberList;
      this.selectedMemberList.forEach((item) => {
        const _amountTerminateMember = <IAmountTerminateMember>{
          effectiveDate: undefined,
          memberId: item.id,
        };
        this.setAmountTerminateMember(_amountTerminateMember);
      });
    },
    setAmountTerminateMember(amountTerminateMember: IAmountTerminateMember) {
      this.terminateMember.amountTerminateMembers.push(amountTerminateMember);
    },
    async setMemberTerminateDate(_value, _id) {
      //set amountTerminateMember effectiveDate by id
      const _index = this.terminateMember.amountTerminateMembers.findIndex(
        (item) => item.memberId === _id
      );
      this.terminateMember.amountTerminateMembers[_index].effectiveDate =
        _value;

      if (this.isAllSelectedMemberHasTerminateDate) {
        await this.loadAmountTerminate();
      }
    },
    async loadAmountTerminate() {
      await BrokerApi.amountTerminate({
        body: {
          amountTerminateMembers: this.getAmountTerminateMember,
        },
      }).then((res) => {
        this.setAmount(res.amount || 0);
      });
    },
    setAmount(amount: number) {
      this.amount = amount;
    },
    removeSelectedMemberList(id: number) {
      this.selectedMemberList = this.selectedMemberList.filter(
        (item) => item.id !== id
      );
      //remove amountTerminateMember by id
      this.terminateMember.amountTerminateMembers =
        this.terminateMember.amountTerminateMembers.filter(
          (item) => item.memberId !== id
        );
    },
    confirmTerminateMember() {
      openModalById("confirm__terminate__member__modal");
    },
    async submitTerminateMember() {
      await BrokerApi.submitTerminateMember({
        body: { amountTerminateMembers: this.getAmountTerminateMember },
      }).then(() => {
        setTimeout(() => {
          router.push({
            name: brRouteName("member-terminate-success"),
            query: { amount: this.amount },
          });
        }, 500);
      });
    },
  },
});
