import JwtService from "./JwtService";

const MEMBER_ID_TOKEN_KEY = "_member_token" as string;

/**
 * @description get token form localStorage
 */
export const getToken = (): string | null => {
  return window.localStorage.getItem(MEMBER_ID_TOKEN_KEY);
};

/**
 * @description save token into localStorage
 * @param token: string
 */
export const saveToken = (token: string): void => {
  window.localStorage.setItem(MEMBER_ID_TOKEN_KEY, token);
  window.localStorage.setItem(JwtService.ID_TOKEN_KEY, token);
};

/**
 * @description remove token form localStorage
 */
export const destroyToken = (): void => {
  window.localStorage.removeItem(MEMBER_ID_TOKEN_KEY);
};

export default { getToken, saveToken, destroyToken };
