export interface IOTPScreenData {
  otp?: string;
  waitingSeconds?: number;
  resendOTPToken?: string;
  phoneMask?: string;
  phoneNumber?: string;
  userName?: string;
  emailMask?: string;
  email?: string;
  resetPasswordToken?: string;
  resetPasswordTokenId?: string;
  otpToken?: string;
  agentCode?: string;
}

export class OTPScreenDataOriginateScreen {
  static MBUpdateBI = "MBUpdateBI"; //MBUpdateBankInfo
  static MBRG = "MBRG"; //MBRegister

  static MBUpdateProfileFirstTime = "MBUpdateProfileFirstTime"; //MBUpdateProfileFirstTime
  static MBUpdatePF = "MBUpdatePF"; //MBUpdateProfile
  static MBSI = "MBSI"; //MBSignIn
  static FDSI = "FDSI"; //FDSignIn
  static CXSI = "CXSI"; //CXSignIn
  static MBChangeP = "MBChangeP"; //MBChangePassword
  static MBResetP = "MBResetP"; //MBResetPassword
  static MBCreateBeneficiary = "MBCreateBeneficiary"; //MBCreateBeneficiary
  static MBUpdateBeneficiary = "MBUpdateBeneficiary"; //MBUpdateBeneficiary
  static MBUpdateBeneficiaryPercent = "MBUpdateBeneficiaryPercent"; //MBUpdateBeneficiaryPercent
  static MBRemoveBeneficiary = "MBRemoveBeneficiary"; //MBRemoveBeneficiary
  static FDResetP = "FDResetP"; //FDResetPassword
  static HRChangeP = "HRChangeP"; //HRChangePassword
  static HRSI = "HRSI"; //HRSignIn
  static BRChangeP = "BRChangeP"; //BRChangePassword
  static BRSI = "BRSI"; //BRSignIn\
  static BRResetP = "BRResetP"; //BRResetPassword
}
