import { defineStore } from "pinia";
import { Hrv2Api, ServiceLogRecordResponse } from "@/api";

export const useHrServiceRequestDetailStore = defineStore({
  id: "serviceRequestDetailStore",
  state: () => ({
    serviceRequestHistoryId: "" as string,
    rawData: undefined as ServiceLogRecordResponse | undefined,
  }),
  getters: {
    getRawData(state) {
      return state.rawData as ServiceLogRecordResponse;
    },
  },
  actions: {
    async loadServiceRequestDetail() {
      await Hrv2Api.serviceLogs1({
        uniqueKey: this.serviceRequestHistoryId,
      }).then((res) => {
        this.rawData = res;
      });
    },
  },
});
