import { createApp } from "vue";
import App from "./App.vue";
import "bootstrap";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";
import VOtpInput from "vue3-otp-input";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import "@/core/plugins/prismjs";
import "@/assets/css/utility.scss";
import ApiServiceMember from "./core/services/ApiServiceMember";
import ApiServiceHR from "./core/services/ApiServiceHR";
import { acceptHMRUpdate, createPinia } from "pinia";
import {
  allowOnlyNumber,
  allowOnlyPhoneNumber,
  dynamicDirective,
} from "@/core/helpers/directive";
import { debounce } from "ts-debounce/dist/src";
import { PiniaDebounce } from "@pinia/plugin-debounce";
import { useFdSubmitClaimCategoryStore } from "@/store/fd/fd-sumbit-claim/FdSubmitClaimCategoryStore";
import { useFdSubmitClaimStore } from "@/store/fd/fd-sumbit-claim/FdSubmitClaimStore";
import { useFdMemberSearchStore } from "@/store/fd/fd-member-search/FdMemberSearchStore";
import { useHrServiceRequestListingStore } from "@/store/hr/hr-service-request/HrServiceRequestListingStore";
import { useHrServiceRequestDetailStore } from "@/store/hr/hr-service-request/HrServiceRequestDetail";
import { useMbServiceRequestListingStore } from "@/store/member/member-service-request/MbServiceRequestListingStore";
import { useMbServiceRequestDetailStore } from "@/store/member/member-service-request/MbServiceRequestDetail";
import { useMemberCriticalIllnessStore } from "@/store/member/member-submit-claim/MemberCriticalIllnessStore";
import { useMemberAccidentalBurnStore } from "@/store/member/member-submit-claim/MemberAccidentalBurnStore";
import { useMemberTotalAndPermanentDismembermentStore } from "@/store/member/member-submit-claim/MemberTotalAndPermanentDisembermentStore";
import { useHrTerminateMember } from "@/store/hr/hr-member/HrTerminateMemberStore";
import { useBrTerminateMember } from "@/store/br/br-member/BrTerminateMemberStore";
import moment from "moment";

// override axios default date format
Date.prototype.toJSON = function () {
  // 2024-02-03T07:00:00+07:00
  return moment(this).format();
};

declare module "@pinia/plugin-debounce" {
  export interface Config {
    Debounce: typeof debounce;
  }
}

const { pinia, app } = createBlock();
initBlock();
useBlock();
directiveBlock();
componentBlock();
piniaHotReloadRegister();
app.mount("#app");

//////////////////////////////////////////////////////////////////////////////////////
function directiveBlock() {
  app.directive("only-number-input", {
    mounted(el, binding) {
      allowOnlyNumber(el, binding);
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
  app.directive("only-phone-number", {
    mounted(el, binding) {
      allowOnlyPhoneNumber(el, binding);
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
  app.directive("no-zero-phone-number", {
    mounted(el, binding) {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        const isRepeat = event.repeat;
        const element = event.target as HTMLInputElement;
        const maxValue = binding.value ?? 9;
        const keyCode = event.key;
        const isBackspace = keyCode === "Backspace";
        const isArrowKey = keyCode.includes("Arrow");
        //remove leading zero
        // and replace with empty string
        // and remove all non-numeric character
        // trim to max value
        if (!isBackspace && !isArrowKey && !isRepeat) {
          el.value = element.value
            .replace(/^0+/, "")
            .replace(/\D/g, "")
            .substring(0, maxValue);
          el.dispatchEvent(new Event("change"));
        }
      });
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
  app.directive("allow-max-characters", {
    mounted(el, binding) {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        const isRepeat = event.repeat;
        const element = event.target as HTMLInputElement;
        const maxValue = binding.value ?? 9;
        const keyCode = event.key;
        const isBackspace = keyCode === "Backspace";
        const isArrowKey = keyCode.includes("Arrow");

        if (!isBackspace && !isArrowKey && !isRepeat) {
          el.value = element.value.substring(0, maxValue);
          el.dispatchEvent(new Event("change"));
        }
      });
      // el.addEventListener("paste", (event) => {
      //   // get pasted data via clipboard API
      //   event.preventDefault();
      //   const clipboardData = event.clipboardData;
      //   const pastedData = clipboardData.getData("Text");
      //   const maxValue = binding.value;
      //   // replace all character except alphanumeric
      //   // and substring to max value
      //   el.value = pastedData.substring(0, maxValue);
      //   el.dispatchEvent(new Event("change"));
      // });
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });

  app.directive("allow-only-alpha-numeric", {
    mounted(el, binding) {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        const isRepeat = event.repeat;
        const element = event.target as HTMLInputElement;
        const maxValue = binding.value;
        const keyCode = event.key;
        const isBackspace = keyCode === "Backspace";
        const isArrowKey = keyCode.includes("Arrow");
        const isBackSpace = keyCode === "Backspace";

        //allow only alphanumeric with trim to max value
        if (!isBackspace && !isArrowKey && !isRepeat && !isBackSpace) {
          el.value = element.value
            .replace(/[^a-zA-Z0-9]/g, "")
            .substring(0, maxValue);
        }
        el.dispatchEvent(new Event("change"));
      });
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
  app.directive("dynamic-directive", {
    mounted(el, binding) {
      dynamicDirective(el, binding);
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
  app.directive("disabled-space", {
    mounted(el) {
      el.addEventListener("keydown", (e) => {
        if (e.key === " ") {
          e.preventDefault();
        }
      });
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });

  app.directive("phone-validate-v2", {
    mounted(el: HTMLInputElement) {
      el.addEventListener("keyup", (event: KeyboardEvent) => {
        const value = el.value;
        const isBackspace = event.key === "Backspace";
        const isFirstCharacterIsNotZero = value[0] !== "0";
        if (isFirstCharacterIsNotZero) {
          if (!isBackspace) {
            el.value = `0${value}`;
            el.dispatchEvent(new Event("change"));
            return;
          }
        }
      });
    },
    unmounted(el: HTMLElement) {
      // remove event listener
      const newElement = el.cloneNode(true);
      el.parentNode?.replaceChild(newElement, el);
    },
  });
}

function useBlock() {
  app.use(pinia);
  app.use(store);
  app.use(router);
  app.use(ElementPlus);
  app.use(i18n);
  pinia.use(PiniaDebounce(debounce));
}

function initBlock() {
  ApiService.init(app, router);
  ApiServiceMember.init(app, router);
  ApiServiceHR.init(app, router);
  initApexCharts(app);
  initInlineSvg(app);
  initVeeValidate();
}

function createBlock() {
  const pinia = createPinia();
  const app = createApp(App);
  return { pinia, app };
}

function componentBlock() {
  app.component("VueDatepicker", Datepicker);
  app.component("v-otp-input", VOtpInput);
}

function piniaHotReloadRegister() {
  const piniaStoreList = [
    useFdSubmitClaimCategoryStore,
    useFdSubmitClaimStore,
    useFdMemberSearchStore,
    useHrServiceRequestListingStore,
    useHrServiceRequestDetailStore,
    useMbServiceRequestListingStore,
    useMbServiceRequestDetailStore,
    useMemberCriticalIllnessStore,
    useMemberAccidentalBurnStore,
    useMemberTotalAndPermanentDismembermentStore,
    useHrTerminateMember,
    useBrTerminateMember,
  ];
  errorIfDuplicate();
  acceptHMR();

  function acceptHMR() {
    if (import.meta.webpackHot) {
      piniaStoreList.forEach((store: any) => {
        import.meta.webpackHot?.accept(
          acceptHMRUpdate(store, import.meta.webpackHot)
        );
      });
    }
  }
  function errorIfDuplicate() {
    // check if piniaStoreList is duplicated
    const piniaStoreListSet = new Set(piniaStoreList);
    if (piniaStoreListSet.size !== piniaStoreList.length) {
      throw new Error("PiniaStoreList is duplicated");
    }
  }
}
