import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import { Modal } from "bootstrap";

const basePath =
  (process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0
    ? window.location.origin + process.env.VUE_APP_API_URL
    : process.env.VUE_APP_API_URL;

const hideModal = (modalEl: HTMLElement | null): void => {
  if (!modalEl) {
    return;
  }

  const myModal = Modal.getInstance(modalEl);
  myModal?.hide();
};

const removeModalBackdrop = (): void => {
  if (document.querySelectorAll(".modal-backdrop.fade.show").length) {
    document.querySelectorAll(".modal-backdrop.fade.show").forEach((item) => {
      item.remove();
    });
  }
};

const mbRoutePath = (base: string): string => {
  return "mb/" + base;
};

const mbRouteName = (base: string): string => {
  return "mb-" + base;
};

const fdRoutePath = (base: string): string => {
  return "fd/" + base;
};

const fdRouteName = (base: string): string => {
  return "fd-" + base;
};

const cxRoutePath = (base: string): string => {
  return "cx/" + base;
};

const cxRouteName = (base: string): string => {
  return "cx-" + base;
};

const hrRoutePath = (base: string): string => {
  return "hr/" + base;
};

const hrRouteName = (base: string): string => {
  return "hr-" + base;
};

const brRoutePath = (base: string): string => {
  return "br/" + base;
};

const brRouteName = (base: string): string => {
  return "br-" + base;
};

function isPdf(meta: IUploadFileUniqueName) {
  return (
    meta?.fileName?.includes(".pdf") ||
    meta?.fileName?.includes(".PDF") ||
    meta?.uniqueName?.includes(".pdf") ||
    meta?.uniqueName?.includes(".PDF") ||
    meta?.fileUrl?.includes(".pdf") ||
    meta?.fileUrl?.includes(".PDF")
  );
}

const routeCheck = {
  isFD: (): boolean => {
    return document.location.href.toLowerCase().indexOf("/fd/") >= 0;
  },
  isMB: (): boolean => {
    return document.location.href.toLowerCase().indexOf("/mb/") >= 0;
  },
  isCX: (): boolean => {
    return document.location.href.toLowerCase().indexOf("/cx/") >= 0;
  },
  isHR: (): boolean => {
    return document.location.href.toLowerCase().indexOf("/hr/") >= 0;
  },
  isBR: (): boolean => {
    return document.location.href.toLowerCase().indexOf("/br/") >= 0;
  },
};

const appRoutePath = (base: string): string => {
  if (routeCheck.isCX()) {
    return cxRoutePath(base);
  }
  return "";
};

const appRouteName = (base: string): string => {
  if (routeCheck.isCX()) {
    return cxRouteName(base);
  } else if (routeCheck.isFD()) {
    return fdRouteName(base);
  } else if (routeCheck.isHR()) {
    return hrRouteName(base);
  } else if (routeCheck.isBR()) {
    return brRouteName(base);
  }
  return mbRouteName(base);
};

export {
  removeModalBackdrop,
  hideModal,
  mbRoutePath,
  mbRouteName,
  fdRouteName,
  fdRoutePath,
  routeCheck,
  cxRoutePath,
  cxRouteName,
  appRoutePath,
  appRouteName,
  hrRoutePath,
  hrRouteName,
  isPdf,
  brRoutePath,
  brRouteName,
};

export const formatTimeToValue = async (data) => {
  const _days = [] as any;
  data.forEach((day) => {
    if (day?.selected)
      _days.push({
        day: day.day,
        timeFrom: day?.timeFrom?.HH + ":" + day.timeFrom?.mm,
        timeTo: day?.timeTo?.HH + ":" + day?.timeTo?.mm,
      });
  });
  return _days;
};

export const formatTimeToValue_2 = async (data) => {
  const _days = [] as any;
  data.forEach((day) => {
    if (day?.selected)
      _days.push({
        day: day.day,
        timeFrom:
          String(day?.timeFrom?.hours).padStart(2, "0") +
          ":" +
          String(day?.timeFrom?.minutes).padStart(2, "0"),
        timeTo:
          String(day?.timeTo?.hours).padStart(2, "0") +
          ":" +
          String(day?.timeTo?.minutes).padStart(2, "0"),
      });
  });
  return _days;
};
