import { defineStore } from "pinia";
import {
  getTranslate,
  scrollToErrorV1,
  yupValidateForm,
} from "@/core/helpers/custom";
import {
  CardTypeStringEnum,
  InputDirective,
  InputTypeEnum,
} from "@/core/data/data";
import {
  IDateInput,
  IInput,
  IRadioInput,
  ISelectInput,
} from "@/interface/IInput";
import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import {
  FDBankInfoResponse,
  FDClaimDetailForEditResponse,
  MBBeneficiaryListResponseItem,
  MBBeneficiaryV2ListResponseItem,
  MemberV2Api,
} from "@/api";
import * as Yup from "yup";
import { number, object, string } from "yup";

interface ICriticalIllnessFormSection {
  informationOfInsured: {
    field: (ISelectInput | IInput)[];
    titles: { km: string; en: string };
  };
  informationRelatedToCriticalIllness: {
    field: (IInput | IDateInput)[];
    titles: { km: string; en: string };
  };
  recordOfMedicalConsultation: {
    field: (IDateInput | IInput | IRadioInput)[];
    titles: { km: string; en: string };
  };
  uploadDocument: {
    field: {
      type: InputTypeEnum.File;
      name?: string;
      formDataKey?: string;
      directives?: InputDirective[];
    }[];
    mainSubTitle: { km: string; en: string };
    titles: { km: string; en: string };
  };
  paymentDetails: { field: IInput[]; titles: { km: string; en: string } };
}

type schemaType =
  | "phoneNumber"
  | "alternatePhoneNumber"
  | "address"
  | "areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness"
  | "pleaseProvideFullDetails";

export const useMemberCriticalIllnessStore = defineStore({
  id: "memberCriticalIllness",
  state: () => ({
    uiData: {
      titles: {
        en: "CRITICAL ILLNESS",
        km: getTranslate("CRITICAL ILLNESS"),
      },
      formSection: <ICriticalIllnessFormSection>{
        informationOfInsured: {
          titles: {
            en: "INFORMATION OF INSURED",
            km: getTranslate("INFORMATION OF INSURED"),
          },
          field: [
            <ISelectInput>{
              label: {
                en: "Name of Insured",
                km: getTranslate("Name of Insured"),
              },
              name: "nameOfInsured",
              value: 1,
              type: InputTypeEnum.Select,
              options: {},
              column: "col-12",
              formDataKey: "nameOfInsuredList",
            },
            <IInput>{
              label: {
                en: "Phone Number",
                km: getTranslate("Phone Number"),
              },
              name: "phoneNumber",
              value: "012480300",
              type: InputTypeEnum.PhoneInput,
              column: "col-12 col-md-6",
              placeholder: "Ex. 012456232",
              // directives: [InputDirective.phoneValidateV2()],
            },
            <IInput>{
              label: {
                en: "Alternate Phone Number",
                km: getTranslate("Alternate Phone Number"),
              },
              name: "alternatePhoneNumber",
              value: "012480300",
              type: InputTypeEnum.PhoneInput,
              column: "col-12 col-md-6",
              placeholder: "Ex. 012456232",
              // directives: [InputDirective.phoneValidateV2()],
            },
            <IInput>{
              label: {
                en: "Address",
                km: getTranslate("Address"),
              },
              name: "address",
              value: "Phnom Penh",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: Phnom Penh, Cambodia",
              // directives: [InputDirective.allowMaxCharacter()],
            },
          ],
        },
        informationRelatedToCriticalIllness: {
          titles: {
            en: "INFORMATION RELATED TO CRITICAL ILLNESS",
            km: getTranslate("INFORMATION RELATED TO CRITICAL ILLNESS"),
          },
          field: [
            <IInput>{
              label: {
                en: "Name of Critical Illness",
                km: getTranslate("Name of Critical Illness"),
              },
              name: "nameOfCriticalIllness",
              value: "Ex. Cancer",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex. Breast Cancer",
              // directives: [InputDirective.allowMaxCharacter()],
            },
            <IInput>{
              label: {
                en: "Describe the symptoms from date of onset",
                km: getTranslate("Describe the symptoms from date of onset"),
              },
              name: "describeTheSymptomsFromDateOfOnset",
              value: "Ex. Cancer",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Describe your symptom…",
              // directives: [InputDirective.allowMaxCharacter(500)],
            },
            <IDateInput>{
              label: {
                en: "Date of First Consultation",
                km: getTranslate("Date of First Consultation"),
              },
              name: "dateOfFirstConsultation",
              value: "2011-10-05T14:48:00.000Z",
              type: InputTypeEnum.Date,
              column: "col-12",
              placeholder: "Ex. 12 March 2022",
              isShowTime: false,
              disabledFuture: true,
            },
            <IInput>{
              label: {
                en: "How long have you been having these signs and symptoms? ( Please indicate exact date if possible )",
                km: getTranslate(
                  "How long have you been having these signs and symptoms? ( Please indicate exact date if possible )"
                ),
              },
              name: "howLongHaveYouBeenHavingTheseSignsAndSymptoms",
              value: "1 month ago",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: 1 month ago",
              // directives: [InputDirective.allowMaxCharacter(500)],
            },
          ],
        },
        recordOfMedicalConsultation: {
          titles: {
            en: "RECORDS OF MEDICAL CONSULTATION / HOSPITALIZATION",
            km: getTranslate(
              "RECORDS OF MEDICAL CONSULTATION / HOSPITALIZATION"
            ),
          },
          field: [
            <IDateInput>{
              label: {
                en: "Date of Consultation",
                km: getTranslate("Date of Consultation"),
              },
              name: "dateOfConsultation",
              value: "2011-10-05T14:48:00.000Z",
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex. 12 March 2022",
              isShowTime: false,
              disabledFuture: true,
            },
            <IInput>{
              label: {
                en: "Name of Doctor/ Hospital / Clinic",
                km: getTranslate("Name of Doctor/ Hospital / Clinic"),
              },
              name: "nameOfDoctorHospitalClinic",
              value: "Heal Medical",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: Heal Medical",
              // directives: [InputDirective.allowMaxCharacter()],
            },
            <IInput>{
              label: {
                en: "Reason for Consultation",
                km: getTranslate("Reason for Consultation"),
              },
              name: "reasonForConsultation",
              value: "Cancer",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Please describe it…",
              // directives: [InputDirective.allowMaxCharacter()],
            },
            <IInput>{
              label: {
                en: "Hospital / Clinic Address",
                km: getTranslate("Hospital / Clinic Address"),
              },
              name: "hospitalClinicAddress",
              value: "Phnom Penh",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: Phnom Penh, Cambodia",
              // directives: [InputDirective.allowMaxCharacter()],
            },
            <IRadioInput>{
              label: {
                en: "Are there any other illness / complaints suffered by you, prior to this critical illness?",
                km: getTranslate(
                  "Are there any other illness / complaints suffered by you, prior to this critical illness?"
                ),
              },
              name: "areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness",
              value: 1,
              type: InputTypeEnum.Radio,
              options: [],
              column: "col-12",
              formDataKey:
                "areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllnessRadioList",
              children: [
                {
                  label: {
                    en: "Please provide full details",
                    km: getTranslate("Please provide full details"),
                  },
                  name: "pleaseProvideFullDetails",
                  value: "Describe it…..",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: Describe it…..",
                  forOption: 1,
                  // directives: [InputDirective.allowMaxCharacter(100)],
                },
              ],
            },
          ],
        },
        uploadDocument: {
          titles: {
            en: "UPLOAD DOCUMENT",
            km: getTranslate("UPLOAD DOCUMENT"),
          },
          mainSubTitle: {
            en: "Please upload these documents to proceed your claim request",
            km: getTranslate(
              "Please upload these documents to proceed your claim request"
            ),
          },
          field: [
            {
              type: InputTypeEnum.File,
              directives: [],
            },
          ],
        },
        paymentDetails: {
          titles: {
            en: "PAYMENT DETAILS",
            km: getTranslate("PAYMENT DETAILS"),
          },
          field: [
            <IInput>{
              label: {
                en: "Bank Name",
                km: getTranslate("Bank Name"),
              },
              name: "bankName",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: ACLEDA Bank",
              isDisabled: true,
            },
            <IInput>{
              label: {
                en: "Bank Account Number",
                km: getTranslate("Bank Account Number"),
              },
              name: "bankAccountNumber",
              value: "123456789",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: 123456789",
              directives: [InputDirective.allowMaxCharacter(50)],
              isDisabled: true,
            },
            <IInput>{
              label: {
                en: "Bank Account Name",
                km: getTranslate("Bank Account Name"),
              },
              name: "bankAccountName",
              value: "Heal Medical",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: Heal Medical",
              directives: [InputDirective.allowMaxCharacter(50)],
              isDisabled: true,
            },
          ],
        },
      },
      alert: {
        title: {
          en: "Terms and conditions",
          km: getTranslate("Terms and conditions"),
        },
      },
      memberList: [] as MBBeneficiaryV2ListResponseItem[],
      formValue: {
        //list of field
        nameOfInsured: undefined as number | undefined,
        phoneNumber: undefined as string | undefined,
        alternatePhoneNumber: undefined as string | undefined,
        address: undefined as string | undefined,
        nameOfCriticalIllness: undefined as string | undefined,
        describeTheSymptomsFromDateOfOnset: undefined as string | undefined,
        dateOfFirstConsultation: undefined as Date | undefined,
        howLongHaveYouBeenHavingTheseSignsAndSymptoms: undefined as
          | string
          | undefined,
        dateOfConsultation: undefined as Date | undefined,
        nameOfDoctorHospitalClinic: undefined as string | undefined,
        reasonForConsultation: undefined as string | undefined,
        hospitalClinicAddress: undefined as string | undefined,
        areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness:
          undefined as string | undefined,
        areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllnessRadioList:
          [
            {
              label: {
                en: "Yes",
                km: getTranslate("Yes_3"),
              },
              value: 1,
            },
            {
              label: {
                en: "No",
                km: getTranslate("No_3"),
              },
              value: 2,
            },
          ],
        pleaseProvideFullDetails: undefined as string | undefined,
        bankName: undefined as string | undefined,
        bankAccountNumber: undefined as string | undefined,
        bankAccountName: undefined as string | undefined,
        cardId: undefined as number | undefined,
        fileList: [] as IUploadFileUniqueName[],
        nameOfInsuredList: {},
        bankNameList: {},
        existedId: undefined as number | undefined,
      },
    },
    validateSchema: object().shape({
      nameOfInsured: number().required({
        en: "Please select name of insured",
        km: "សូមជ្រើសរើសឈ្មោះអ្នកត្រូវបានធានារ៉ាប់រង",
      }),
      phoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Phone number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Phone number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .required({
          en: "Please enter phone number",
          km: "សូមបញ្ចូលលេខទូរស័ព្ទ",
        })
        .label("Phone Number"),
      alternatePhoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Alt Phone Number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Alt Phone Number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .nullable()
        .label("Alt Phone Number"),
      address: string()
        .max(100, {
          en: "Address must be at most 100 characters",
          km: "អាសយដ្ឋានត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
        })
        .required(),
      nameOfCriticalIllness: string()
        .max(100, {
          en: "Name of critical illness must be at most 100 characters",
          km: "ឈ្មោះជំងឺដែលធ្ងន់ធ្ងរត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
        })
        .required(),
      describeTheSymptomsFromDateOfOnset: string()
        .max(500, {
          en: "Description must be at most 500 characters",
          km: "ការពិពណ៌នាត្រូវមានតួអក្សរច្រើនបំផុត ៥០០ ខ្ទង់",
        })
        .required(),
      howLongHaveYouBeenHavingTheseSignsAndSymptoms: string()
        .max(500, {
          en: "Description must be at most 500 characters",
          km: "ការពិពណ៌នាត្រូវមានតួអក្សរច្រើនបំផុត ៥០០ ខ្ទង់",
        })
        .required(),
      nameOfDoctorHospitalClinic: string()
        .max(100, {
          en: "Name of Doctor/ Hospital / Clinic must be at most 100 characters",
          km: "ឈ្មោះគ្រូពេទ្យ/មន្ទីរពេទ្យ/គ្លីនីកត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
        })
        .required({
          en: "Please enter name of doctor/hospital/clinic",
          km: "សូមបញ្ចូលឈ្មោះគ្រូពេទ្យ/មន្ទីរពេទ្យ/គ្លីនីក",
        }),
      reasonForConsultation: string()
        .max(100, {
          en: "Reason for consultation must be at most 100 characters",
          km: "ហេតុផលសម្រាប់ការពិភាក្សាត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
        })
        .required({
          en: "Please enter reason for consultation",
          km: "សូមបញ្ចូលហេតុផលសម្រាប់ការពិភាក្សា",
        }),
      hospitalClinicAddress: string()
        .max(100, {
          en: "Address must be at most 100 characters",
          km: "អាសយដ្ឋានត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
        })
        .required({
          en: "Please enter hospital/clinic address",
          km: "សូមបញ្ចូលអាសយដ្ឋានមន្ទីរពេទ្យ/គ្លីនីក",
        }),
      dateOfConsultation: string().required(),
      dateOfFirstConsultation: string().required(),
      pleaseProvideFullDetails: string().when(
        "areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness",
        {
          is: (value: string) => value === "1",
          then: string()
            .max(100, {
              en: "Full details must be at most 100 characters",
              km: "ព័ត៌មានពេញលេញត្រូវមានតួអក្សរច្រើនបំផុត ១០០ ខ្ទង់",
            })
            .required({
              en: "Please provide full details",
              km: "សូមបញ្ចូលព័ត៌មានពេញលេញ",
            }),
        }
      ),
      areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness:
        string().required(),
      fileUpload: Yup.string()
        .matches(new RegExp("\\b" + "true" + "\\b"), {
          message: {
            en: "File upload is required.",
            km: "ឯកសារត្រូវបានទាមទារ",
          },
        })
        .required({
          en: "File upload is required.",
          km: "ឯកសារត្រូវបានទាមទារ",
        }),
      bankName: string().required(),
    }),
    validateResult: {} as Record<schemaType, string>,
    existedId: undefined as number | undefined,
    existedData: undefined as FDClaimDetailForEditResponse | undefined,
    isInEditSession: false,
    cardDetail: {
      policyNumber: "",
      memberNumber: "",
      type: "",
    },
  }),
  getters: {
    getFileList: (state) => state.uiData.formValue.fileList,
    getCardId: (state) => state.uiData.formValue.cardId,
    getCurrentMember: (state) => {
      //get current member by current member id
      return state.uiData.memberList.find(
        (member) => member.id === state.uiData.formValue.nameOfInsured
      );
    },
    getAreThereOtherIllness: (state) => {
      return state.uiData.formValue
        .areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness;
    },
    getCardDisplay: (state) => {
      if (state.cardDetail.type === CardTypeStringEnum.INDIVIDUAL) {
        return `POLICY NUMBER : ${state.cardDetail.policyNumber}`;
      } else {
        return `MEMBER NUMBER : ${state.cardDetail.memberNumber}`;
      }
    },
    isAnyFileUploaded(): boolean {
      return this.uiData.formValue.fileList.length > 0;
    },
  },
  actions: {
    updateUploadImage(data: IUploadFileUniqueName[]) {
      data.forEach((fileMeta) => {
        fileMeta.uniqueName = fileMeta.fileName;
        this.getFileList.push(fileMeta);
        // insert uniqueName to each data by using fileName
      });
    },
    removeImageItem(fileName: string) {
      const _filterList = this.getFileList.filter(
        (meta) => fileName !== (meta.uniqueName ?? meta.fileName)
      );
      this.setFileList(_filterList);
    },
    setFileList(data: IUploadFileUniqueName[]) {
      this.uiData.formValue.fileList = data;
    },
    async getMemberList() {
      await MemberV2Api.beneficiaries({ memberId: this.getCardId }).then(
        (data) => {
          this.setMemberList(data.items ?? []);
        }
      );
    },
    setMemberList(data: MBBeneficiaryListResponseItem[]) {
      this.uiData.memberList = data;
      this.pushValuesToNameOfInsured();
    },
    pushValuesToNameOfInsured() {
      this.uiData.formValue.nameOfInsuredList = this.uiData.memberList.reduce(
        (acc, cur) => {
          return { ...acc, [cur.id ?? ""]: cur.name };
        },
        {}
      ) as object;
    },
    async getBankInfo() {
      await MemberV2Api.bankInfo({ memberId: this.getCardId }).then((data) => {
        this.setBankInfo(data);
      });
    },
    setBankInfo(data: FDBankInfoResponse) {
      const _formValue = this.uiData.formValue;
      _formValue.bankName = data.bankName;
      _formValue.bankAccountNumber = data.bankAccountNumber;
      _formValue.bankAccountName = data.bankAccountName;
    },
    async validateForm() {
      this.validateResult = (await yupValidateForm(
        this.validateSchema,
        this.uiData.formValue
      )) as Record<schemaType, string>;
      scrollToErrorV1(this.validateResult);
    },
    async loadExistingClaim() {
      await MemberV2Api.claimDetailForEdit({
        id: Number(this.existedId),
      }).then((data) => {
        this.existedData = data;
        this.setEditFormValue(data);
      });
      this.isInEditSession = true;
    },
    setEditFormValue(data: FDClaimDetailForEditResponse) {
      const _formValue = this.uiData.formValue;
      _formValue.address = undefined;
      // _formValue.areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllnessRadioList =
      //   [];
      // _formValue.bankAccountName = undefined;
      // _formValue.bankAccountNumber = undefined;
      // _formValue.bankName = undefined;
      // _formValue.bankNameList = {};
      _formValue.cardId = this.getCardId;
      _formValue.nameOfInsuredList = {};
      _formValue.nameOfInsured = data.memberId;
      _formValue.phoneNumber = data.phoneNumber;
      _formValue.alternatePhoneNumber = data.alternativePhoneNumber;
      _formValue.nameOfCriticalIllness = data.nameCriticalIllness;
      _formValue.describeTheSymptomsFromDateOfOnset = data.desSymptomsDateOnset;
      _formValue.dateOfFirstConsultation = data.dateFirstConsultation;
      _formValue.howLongHaveYouBeenHavingTheseSignsAndSymptoms =
        data.howLongSignsSymptoms;
      _formValue.dateOfConsultation = data.dateConsultation;
      _formValue.nameOfDoctorHospitalClinic = data.nameOfDoctor;
      _formValue.reasonForConsultation = data.reasonConsultation;
      _formValue.hospitalClinicAddress = data.hospitalClinicAddress;
      _formValue.areThereAnyOtherIllnessComplaintsSufferedByYouPriorToThisCriticalIllness =
        data.isCriticalIllness ? "1" : "2";
      _formValue.pleaseProvideFullDetails = data.fullDetail;
      _formValue.fileList = data.uploadedFiles ?? [];
      _formValue.existedId = Number(this.existedId);
      _formValue.address = data.address;
    },
    async loadCardData() {
      await MemberV2Api.profile({ memberId: this.getCardId })
        .then((data) => {
          this.cardDetail = {
            memberNumber: data.policyProfile?.memberNumber ?? "",
            policyNumber: data.policyProfile?.policyNumber ?? "",
            type: data.policyProfile?.type ?? "",
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
