import { RouteRecordRaw } from "vue-router";

const globalRouteList: Array<RouteRecordRaw> = [
  {
    path: "/fd/preview-file",
    name: "fd-preview-file",
    component: () => import("@/views/PreviewFile.vue"),
  },
  {
    path: "/mb/preview-file",
    name: "mb-preview-file",
    component: () => import("@/views/PreviewFile.vue"),
  },
  {
    path: "/hr/preview-file",
    name: "hr-preview-file",
    component: () => import("@/views/PreviewFile.vue"),
  },
  {
    path: "/br/preview-file",
    name: "br-preview-file",
    component: () => import("@/views/PreviewFile.vue"),
  },
  {
    path: "/mb/preview-certificate-of-insurance",
    name: "mb-preview-certificate-of-insurance",
    component: () => import("@/views/PreviewFileCertificateOfInsurance.vue"),
  },
];

export default globalRouteList;
