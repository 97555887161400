import { defineStore } from "pinia";
import { convertDateToISOString, getTranslate } from "@/core/helpers/custom";
import {
  CardTypeStringEnum,
  defaultComponent,
  InputDirective,
  InputTypeEnum,
} from "@/core/data/data";
import {
  ICheckboxInput,
  IDateInput,
  IInput,
  IRadioInput,
  IRadioOption,
} from "@/interface/IInput";
import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import {
  FDBankInfoResponse,
  FDClaimDetailForEditResponse,
  MBBeneficiaryListResponseItem,
  MBBeneficiaryV2ListResponseItem,
  MBBodyInjuredTypeListResponseItem,
  MBConfinedListResponseItem,
  MBTPDWasWueListResponseItem,
  MemberV2Api,
} from "@/api";
import * as Yup from "yup";
import { array, bool, number, object, string } from "yup";

export interface IPartOfBodyInjureList {
  label: { km: string | undefined; en: string | undefined };
  id: number | undefined;
  numberPartInjured: string;
  isChecked: boolean;
}

export const useMemberTotalAndPermanentDismembermentStore = defineStore({
  id: "memberTotalAndPermanentDismemberment",
  state: () => ({
    uiData: {
      titles: {
        en: "TOTAL & PERMANENT DISMEMBERMENT ( TPD )",
        km: getTranslate("TOTAL & PERMANENT DISMEMBERMENT ( TPD )"),
      },
      formSection: {
        informationOfInsured: defaultComponent.nameOfInsured(),
        informationRelatedToTpd: {
          titles: {
            en: "INFORMATION RELATED TO TPD",
            km: getTranslate("INFORMATION RELATED TO TPD"),
          },
          field: [
            <ICheckboxInput>{
              label: {
                en: "Parts of body injured or kind of injury",
                km: getTranslate("Parts of body injured or kind of injury"),
              },
              name: "partOfBodyInjureList",
              type: InputTypeEnum.CustomCheckboxPartOfInjured,
              options: [],
              column: "col-12",
              formDataKey: "partOfBodyInjureList",
            },
            <IInput>{
              label: {
                en: "Current exact duties after TPD",
                km: getTranslate("Current exact duties after TPD"),
              },
              name: "currentExactDutiesAfterTpd",
              value: "Current exact duties after TPD",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex. Current exact duties after TPD",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Current monthly income ( USD )",
                km: getTranslate("Current monthly income ( USD )"),
              },
              name: "currentMonthlyIncome",
              value: "1000",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex. $ 1000",
              directives: [InputDirective.unsignedDecimal(7)],
            },
            <IInput>{
              label: {
                en: "Name of current business or employer",
                km: getTranslate("Name of current business or employer"),
              },
              name: "nameOfCurrentBusinessOrEmployer",
              value: "AIA Cambodia",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex. AIA Cambodia",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Address of current business or employer",
                km: getTranslate("Address of current business or employer"),
              },
              name: "addressOfCurrentBusinessOrEmployer",
              value:
                "No. 1, St. 110, Sangkat Boeung Keng Kang 1, Khan Chamkarmon, Phnom Penh",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: Phnom Penh, Cambodia",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Phone number of current business or employer",
                km: getTranslate(
                  "Phone number of current business or employer"
                ),
              },
              name: "phoneNumberOfCurrentBusinessOrEmployer",
              value: "012345678",
              type: InputTypeEnum.PhoneInput,
              column: "col-12",
              placeholder: "Ex: 012345678",
              directives: [InputDirective.phoneValidateV2()],
            },
            <IInput>{
              label: {
                en: "Exact duties before TPD",
                km: getTranslate("Exact duties before TPD"),
              },
              name: "exactDutiesBeforeTpd",
              value: "Manager",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: Manager",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Monthly income before TPD ( USD )",
                km: getTranslate("Monthly income before TPD ( USD )"),
              },
              name: "monthlyIncomeBeforeTpd",
              value: "1000",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: $ 1000",
              directives: [InputDirective.unsignedDecimal(7)],
            },
            <IInput>{
              label: {
                en: "Name of business or employer before TPD",
                km: getTranslate("Name of business or employer before TPD"),
              },
              name: "nameOfBusinessOrEmployerBeforeTpd",
              value: "AIA Cambodia",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: AIA Cambodia",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Address of business or employer before TPD",
                km: getTranslate("Address of business or employer before TPD"),
              },
              name: "addressOfBusinessOrEmployerBeforeTpd",
              value: "Phnom Penh, Cambodia",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: Phnom Penh, Cambodia",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IInput>{
              label: {
                en: "Phone number before TPD",
                km: getTranslate("Phone number before TPD"),
              },
              name: "phoneNumberBeforeTpd",
              value: "012345678",
              type: InputTypeEnum.PhoneInput,
              column: "col-12",
              placeholder: "Ex: 012345678",
              directives: [InputDirective.phoneValidateV2()],
            },
            <IDateInput>{
              label: {
                en: "Date of last working day",
                km: getTranslate("Date of last working day"),
              },
              name: "dateOfLastWorkingDay",
              value: convertDateToISOString(new Date("2020-01-01")),
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex: 12 March 2022",
              isShowTime: false,
            },
            <IDateInput>{
              label: {
                en: "Date you are returned to work",
                km: getTranslate("Date you are returned to work"),
              },
              name: "dateYouAreReturnedToWork",
              value: convertDateToISOString(new Date("2020-01-01")),
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex: 12 March 2022",
            },

            <IRadioInput>{
              label: {
                en: "Are you currently confined any of the following:",
                km: getTranslate(
                  "Are you currently confined any of the following:"
                ),
              },
              name: "areYouCurrentlyConfinedAnyOfTheFollowing",
              type: InputTypeEnum.Radio,
              options: [],
              column: "col-12",
              children: [],
              formDataKey: "confinedRadioList",
            },
            <IRadioInput>{
              label: {
                en: "If TPD was due to",
                km: getTranslate("If TPD was due to"),
              },
              name: "ifTpdWasDueTo",
              type: InputTypeEnum.Radio,
              options: <IRadioInput[]>[],
              column: "col-12",
              children: [
                //forOption: 1 = Accident
                //forOption: 2 = Illness
                <IDateInput>{
                  label: {
                    en: "Date and Time of Accident",
                    km: getTranslate("Date and Time of Accident"),
                  },
                  name: "dateAndTimeOfAccident",
                  value: convertDateToISOString(new Date("2020-01-01")),
                  type: InputTypeEnum.Date,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: 12 March 2022",
                  forOption: 1,
                  isShowTime: true,
                },
                <IInput>{
                  label: {
                    en: "Where did it happen?",
                    km: getTranslate("Where did it happen?"),
                  },
                  name: "whereDidItHappen",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 1,
                },
                <IInput>{
                  label: {
                    en: "How did it happen?",
                    km: getTranslate("How did it happen?"),
                  },
                  name: "howDidItHappen",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 1,
                },
                <IInput>{
                  label: {
                    en: "The illness caused of TPD happen?",
                    km: getTranslate("The illness caused of TPD happen?"),
                  },
                  name: "theIllnessCausedOfTpdHappened",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: Headaches",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Describe the summary symptoms",
                    km: getTranslate("Describe the summary symptoms"),
                  },
                  name: "describeTheSummarySymptoms",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: Please describe it...",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Date symptoms started",
                    km: getTranslate("Date symptoms started"),
                  },
                  name: "dateSymptomsStarted",
                  value: convertDateToISOString(new Date("2020-01-01")),
                  type: InputTypeEnum.Date,
                  column: "col-12",
                  placeholder: "Ex: 12 March 2022",
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "How many months had insured been having these symptoms prior to the first consultation?",
                    km: getTranslate(
                      "How many months had insured been having these symptoms prior to the first consultation?"
                    ),
                  },
                  name: "howManyMonthsHadInsuredBeenHavingTheseSymptomsPriorToTheFirstConsultation",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: 5 months",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "The name of doctor / hospital / clinic for first consulted for this illness",
                    km: getTranslate(
                      "The name of doctor / hospital / clinic for first consulted for this illness"
                    ),
                  },
                  name: "theNameOfDoctorHospitalClinicForFirstConsultedForThisIllness",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: Khema Hospital",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Doctor comment after first consultation",
                    km: getTranslate("Doctor comment after first consultation"),
                  },
                  name: "doctorCommentAfterFirstConsultation",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Name of doctor / hospital / clinic",
                    km: getTranslate("Name of doctor / hospital / clinic"),
                  },
                  name: "nameOfDoctorHospitalClinic",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Reason for Consultation",
                    km: getTranslate("Reason for Consultation"),
                  },
                  name: "reasonForConsultation",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
                <IInput>{
                  label: {
                    en: "Address of Doctors / Hospital / Clinic",
                    km: getTranslate("Address of Doctors / Hospital / Clinic"),
                  },
                  name: "addressOfDoctorsHospitalClinic",
                  value: "Phnom Penh, Cambodia",
                  type: InputTypeEnum.Text,
                  column: "col-12 col-md-6",
                  placeholder: "Ex: Phnom Penh, Cambodia",
                  directives: [InputDirective.allowMaxCharacter(100)],
                  forOption: 2,
                },
              ],
              formDataKey: "tpdDueToRadioList",
            },
          ],
        },
        uploadDocument: defaultComponent.uploadDocument(),
        paymentDetails: defaultComponent.paymentDetails(),
      },
      formValue: {
        //list of field
        nameOfInsured: undefined as number | undefined,
        phoneNumber: undefined as string | undefined,
        alternatePhoneNumber: undefined as string | undefined,
        address: undefined as string | undefined,
        currentExactDutiesAfterTpd: undefined as string | undefined,
        currentMonthlyIncome: undefined as number | undefined,
        nameOfCurrentBusinessOrEmployer: undefined as string | undefined,
        addressOfCurrentBusinessOrEmployer: undefined as string | undefined,
        phoneNumberOfCurrentBusinessOrEmployer: undefined as string | undefined,
        exactDutiesBeforeTpd: undefined as string | undefined,
        monthlyIncomeBeforeTpd: undefined as number | undefined,
        nameOfBusinessOrEmployerBeforeTpd: undefined as string | undefined,
        addressOfBusinessOrEmployerBeforeTpd: undefined as string | undefined,
        phoneNumberBeforeTpd: undefined as string | undefined,
        dateOfLastWorkingDay: undefined as Date | undefined,
        dateYouAreReturnedToWork: undefined as Date | undefined,
        dateOfFirstConsultation: undefined as Date | undefined,
        ifTpdWasDueTo: undefined as number | undefined,
        tpdDueToRadioList: [] as IRadioOption[],
        dateAndTimeOfAccident: undefined as Date | undefined,
        whereDidItHappen: undefined as string | undefined,
        howDidItHappen: undefined as string | undefined,
        theIllnessCausedOfTpdHappened: undefined as string | undefined,
        describeTheSummarySymptoms: undefined as string | undefined,
        dateSymptomsStarted: undefined as Date | undefined,
        howManyMonthsHadInsuredBeenHavingTheseSymptomsPriorToTheFirstConsultation:
          undefined as string | undefined,
        theNameOfDoctorHospitalClinicForFirstConsultedForThisIllness:
          undefined as string | undefined,
        doctorCommentAfterFirstConsultation: undefined as string | undefined,
        nameOfDoctorHospitalClinic: undefined as string | undefined,
        reasonForConsultation: undefined as string | undefined,
        addressOfDoctorsHospitalClinic: undefined as string | undefined,
        partOfBodyInjureList: [] as IPartOfBodyInjureList[],
        areYouCurrentlyConfinedAnyOfTheFollowing: undefined as
          | number
          | undefined,
        confinedRadioList: [] as IRadioOption[],
        bankName: undefined as string | undefined,
        bankAccountNumber: undefined as string | undefined,
        bankAccountName: undefined as string | undefined,
        cardId: undefined as number | undefined,
        fileList: [] as IUploadFileUniqueName[],
        nameOfInsuredList: {},
        bankNameList: {},
        existedId: undefined as number | undefined,
      },
      confinedList: [] as MBConfinedListResponseItem[],
      memberList: [] as MBBeneficiaryV2ListResponseItem[],
      alert: {
        title: {
          en: "Terms and conditions",
          km: getTranslate("Terms and conditions"),
        },
      },
    },
    existedId: undefined as number | undefined,
    existedData: undefined as FDClaimDetailForEditResponse | undefined,
    isInEditSession: false,
    cardDetail: {
      policyNumber: "",
      memberNumber: "",
      type: "",
      effectiveDate: undefined as string | undefined,
    },
    validateSchema: object().shape({
      nameOfInsured: number().required(),
      phoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Phone number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Phone number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .required({
          en: "Please enter phone number",
          km: "សូមបញ្ចូលលេខទូរស័ព្ទ",
        })
        .label("Phone Number"),
      alternatePhoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Alt Phone Number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Alt Phone Number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .nullable()
        .label("Alt Phone Number"),
      address: string().max(100).required(),
      fileUpload: Yup.string()
        .matches(new RegExp("\\b" + "true" + "\\b"), {
          message: {
            en: "File upload is required.",
            km: "ឯកសារត្រូវបានទាមទារ",
          },
        })
        .required({
          en: "File upload is required.",
          km: "ឯកសារត្រូវបានទាមទារ",
        }),
      bankName: string().required(),
      // at least one of partOfBodyInjureList is checked
      currentExactDutiesAfterTpd: string().max(100).required(),
      currentMonthlyIncome: number().required(),
      nameOfCurrentBusinessOrEmployer: string().max(100).required(),
      addressOfCurrentBusinessOrEmployer: string().max(100).required(),
      phoneNumberOfCurrentBusinessOrEmployer: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Phone number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Phone number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .required({
          en: "Please enter phone number",
          km: "សូមបញ្ចូលលេខទូរស័ព្ទ",
        })
        .label("Phone Number"),
      exactDutiesBeforeTpd: string().max(100).required(),
      monthlyIncomeBeforeTpd: number().required(),
      nameOfBusinessOrEmployerBeforeTpd: string().max(100).required(),
      addressOfBusinessOrEmployerBeforeTpd: string().max(100).required(),
      phoneNumberBeforeTpd: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Phone number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Phone number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .required({
          en: "Please enter phone number",
          km: "សូមបញ្ចូលលេខទូរស័ព្ទ",
        })
        .label("Phone Number"),
      dateOfLastWorkingDay: string().required(),
      dateYouAreReturnedToWork: string().required(),
      areYouCurrentlyConfinedAnyOfTheFollowing: number().required(),
      ifTpdWasDueTo: number().required(),
      dateAndTimeOfAccident: string().when("ifTpdWasDueTo", {
        // 1 is accident
        is: (val) => val === 1,
        then: string().required(),
      }),
      whereDidItHappen: string().when("ifTpdWasDueTo", {
        // 1 is accident
        is: (val) => val === 1,
        then: string().max(100).required(),
      }),
      howDidItHappen: string().when("ifTpdWasDueTo", {
        // 1 is accident
        is: (val) => val === 1,
        then: string().max(100).required(),
      }),
      theIllnessCausedOfTpdHappened: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
      describeTheSummarySymptoms: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
      dateSymptomsStarted: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().required(),
      }),
      howManyMonthsHadInsuredBeenHavingTheseSymptomsPriorToTheFirstConsultation:
        string().when("ifTpdWasDueTo", {
          // 2 is illness
          is: (val) => val === 2,
          then: string().max(100).required(),
        }),
      theNameOfDoctorHospitalClinicForFirstConsultedForThisIllness:
        string().when("ifTpdWasDueTo", {
          // 2 is illness
          is: (val) => val === 2,
          then: string().max(100).required(),
        }),
      doctorCommentAfterFirstConsultation: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
      nameOfDoctorHospitalClinic: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
      reasonForConsultation: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
      addressOfDoctorsHospitalClinic: string().when("ifTpdWasDueTo", {
        // 2 is illness
        is: (val) => val === 2,
        then: string().max(100).required(),
      }),
    }),
  }),
  getters: {
    getFileList: (state) => state.uiData.formValue.fileList,
    getCardId: (state) => state.uiData.formValue.cardId,
    getCurrentMember: (state) => {
      //get current member by current member id
      return state.uiData.memberList.find(
        (member) => member.id === state.uiData.formValue.nameOfInsured
      );
    },

    getCurrentConfined: (state) => {
      //get current confined by current confined id
      return state.uiData.confinedList.find(
        (confined) =>
          confined.id ===
          Number(
            state.uiData.formValue.areYouCurrentlyConfinedAnyOfTheFollowing
          )
      );
    },
    getCurrentTpdDueTo: (state) => {
      //get current tpd due to by current tpd due to id
      return state.uiData.formValue.tpdDueToRadioList.find((tpdDueTo) => {
        return (
          String(tpdDueTo.value) ===
          String(state.uiData.formValue.ifTpdWasDueTo)
        );
      });
    },
    getOnlySelectedPartOfBodyInjureList: (state) => {
      //get only selected part of body injure list
      return state.uiData.formValue.partOfBodyInjureList.filter(
        (partOfBodyInjure) => partOfBodyInjure.isChecked
      );
    },
    getCardDisplay: (state) => {
      if (state.cardDetail.type === CardTypeStringEnum.INDIVIDUAL) {
        return `POLICY NUMBER : ${state.cardDetail.policyNumber}`;
      } else {
        return `MEMBER NUMBER : ${state.cardDetail.memberNumber}`;
      }
    },
    isAnyFileUploaded: (state) => {
      return state.uiData.formValue.fileList.length > 0;
    },
    isAnyPartOfBodyInjureSelected: (state) => {
      return state.uiData.formValue.partOfBodyInjureList.some(
        (partOfBodyInjure) => partOfBodyInjure.isChecked
      ) as boolean;
    },
  },
  actions: {
    updateUploadImage(data: IUploadFileUniqueName[]) {
      data.forEach((fileMeta) => {
        fileMeta.uniqueName = fileMeta.fileName;
        this.getFileList.push(fileMeta);
        // insert uniqueName to each data by using fileName
      });
    },
    removeImageItem(fileName: string) {
      const _filterList = this.getFileList.filter(
        (meta) => fileName !== (meta.uniqueName ?? meta.fileName)
      );
      this.setFileList(_filterList);
    },
    setFileList(data: IUploadFileUniqueName[]) {
      this.uiData.formValue.fileList = data;
    },
    async getMemberList() {
      await MemberV2Api.beneficiaries({ memberId: this.getCardId })
        .then((data) => {
          this.setMemberList(data.items ?? []);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    setMemberList(data: MBBeneficiaryListResponseItem[]) {
      this.uiData.memberList = data;
      this.uiData.formValue.nameOfInsuredList = this.uiData.memberList.reduce(
        (acc, cur) => {
          return { ...acc, [cur.id ?? ""]: cur.name };
        },
        {}
      ) as object;
    },

    async getBankInfo() {
      await MemberV2Api.bankInfo({ memberId: this.getCardId })
        .then((data) => {
          this.setBankInfo(data);
        })
        .catch((err) => {
          console.log(err.data);
        });
    },
    setBankInfo(data: FDBankInfoResponse) {
      const _formValue = this.uiData.formValue;
      _formValue.bankName = data.bankName;
      _formValue.bankAccountNumber = data.bankAccountNumber;
      _formValue.bankAccountName = data.bankAccountName;
    },
    setCardId(id: number) {
      this.uiData.formValue.cardId = id;
    },
    async getConfinedList() {
      await MemberV2Api.confined().then((data) => {
        this.setConfinedList(data.data as MBConfinedListResponseItem[]);
      });
    },
    setConfinedList(data: MBConfinedListResponseItem[]) {
      this.uiData.confinedList = data;
      this.uiData.formValue.confinedRadioList = this.uiData.confinedList.map(
        (confined) => {
          return <IRadioOption>{
            label: {
              en: confined.name,
              km: confined.nameAlt,
            },
            value: confined.id,
          };
        }
      );
    },
    async getTPDWasDueToList() {
      await MemberV2Api.tpdWasWue().then((data) => {
        this.setTPDWasDueTo(data.data as MBTPDWasWueListResponseItem[]);
      });
    },
    setTPDWasDueTo(data: MBTPDWasWueListResponseItem[]) {
      this.uiData.formValue.tpdDueToRadioList = data.map((tpdWasDueTo) => {
        return <IRadioOption>{
          label: {
            en: tpdWasDueTo.name,
            km: tpdWasDueTo.nameAlt,
          },
          value: tpdWasDueTo.id,
        };
      });
    },
    async getPartOfBodyInjuredOrKindOfInjuryList() {
      await MemberV2Api.bodyInjuredType().then((data) => {
        this.setPartOfBodyInjuredOrKindOfInjuryList(
          data.data as MBBodyInjuredTypeListResponseItem[]
        );
      });
    },
    setPartOfBodyInjuredOrKindOfInjuryList(
      data: MBBodyInjuredTypeListResponseItem[]
    ) {
      if (this.uiData.formValue.partOfBodyInjureList.length > 0) return;
      this.uiData.formValue.partOfBodyInjureList = data.map(
        (partOfBodyInjure) => {
          return <IPartOfBodyInjureList>{
            label: {
              en: partOfBodyInjure.name,
              km: partOfBodyInjure.nameAlt,
            },
            id: partOfBodyInjure.id,
            numberPartInjured: "",
            isChecked: false,
          };
        }
      ) as IPartOfBodyInjureList[];
    },
    async loadExistingClaim() {
      await MemberV2Api.claimDetailForEdit({
        id: Number(this.existedId),
      }).then((data) => {
        this.existedData = data;
        this.setEditFormValue(data);
      });
      this.isInEditSession = true;
    },
    setEditFormValue(data: FDClaimDetailForEditResponse) {
      const _form = this.uiData.formValue;
      _form.existedId = this.existedId;
      _form.nameOfInsured = data.memberId;
      _form.phoneNumber = data.phoneNumber;
      _form.alternatePhoneNumber = data.alternativePhoneNumber;
      _form.address = data.address;
      _form.currentExactDutiesAfterTpd = data.exactDutiesAfterTPD;
      _form.currentMonthlyIncome = data.monthlyIncome;
      _form.nameOfCurrentBusinessOrEmployer = data.nameBusinessOrEmployer;
      _form.addressOfCurrentBusinessOrEmployer = data.addressBusinessOrEmployer;
      _form.phoneNumberOfCurrentBusinessOrEmployer =
        data.phoneBusinessOrEmployer;
      _form.exactDutiesBeforeTpd = data.exactDutiesBeforeTPD;
      _form.monthlyIncomeBeforeTpd = data.monthlyIncomeBeforeTPD;
      _form.nameOfBusinessOrEmployerBeforeTpd =
        data.nameBusinessOrEmployerBeforeTPD;
      _form.addressOfBusinessOrEmployerBeforeTpd =
        data.addressBusinessOrEmployerBeforeTPD;
      _form.phoneNumberBeforeTpd = data.phoneBusinessOrEmployerBeforeTPD;
      _form.dateOfLastWorkingDay = data.dateLastWorkingDay;
      _form.dateYouAreReturnedToWork = data.dateReturnWork;
      _form.areYouCurrentlyConfinedAnyOfTheFollowing = data.confined?.id;
      _form.ifTpdWasDueTo = data.tpdWasWue?.id;
      _form.dateAndTimeOfAccident = data.dateOfEvent;
      _form.whereDidItHappen = data.placeOfAccident;
      _form.howDidItHappen = data.causeOfAccident;
      _form.fileList = data.uploadedFiles ?? [];
      _form.theIllnessCausedOfTpdHappened = data.illnessCausedTPD;
      _form.describeTheSummarySymptoms = data.describeSymptom;
      _form.dateSymptomsStarted = data.dateSymptomsStarted;
      _form.howManyMonthsHadInsuredBeenHavingTheseSymptomsPriorToTheFirstConsultation =
        data.manyMonthsInsuredFirstConsultation;
      _form.theNameOfDoctorHospitalClinicForFirstConsultedForThisIllness =
        data.nameDoctorFristConsultedIllness;
      _form.doctorCommentAfterFirstConsultation =
        data.doctorCommentFristConsulted;
      _form.nameOfDoctorHospitalClinic = data.nameOfDoctor;
      _form.reasonForConsultation = data.reasonConsultation;
      _form.addressOfDoctorsHospitalClinic = data.hospitalClinicAddress;
      setPartOfBodyInjureListForEdit();

      function setPartOfBodyInjureListForEdit() {
        _form.partOfBodyInjureList = _form.partOfBodyInjureList.map(
          (partOfBodyInjure) => {
            const _isExist = data.bodyInjuredTypeItem?.find(
              (bodyInjuredTypeItem) => {
                return (
                  bodyInjuredTypeItem.bodyInjuredTypeItem?.id ===
                  partOfBodyInjure.id
                );
              }
            );
            if (_isExist) {
              partOfBodyInjure.isChecked = true;
              partOfBodyInjure.numberPartInjured = String(
                _isExist.numberPartInjured
              );
            }

            return partOfBodyInjure;
          }
        );
      }
    },
    async loadCardData() {
      await MemberV2Api.profile({ memberId: this.getCardId })
        .then((data) => {
          this.cardDetail = {
            memberNumber: data.policyProfile?.memberNumber ?? "",
            policyNumber: data.policyProfile?.policyNumber ?? "",
            type: data.policyProfile?.type ?? "",
            effectiveDate: data.policyProfile?.effectiveDate?.toString() ?? "",
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
