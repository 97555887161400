import { defineStore } from "pinia";
import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import router from "@/router";
import { getTranslate } from "@/core/helpers/custom";
import { IMember } from "@/interface/front-desk/memberInterface";
import { IBankInfo } from "@/interface/front-desk/claimSubmitInterface";
import { CardTypeStringEnum, ClaimTypeStringIdEnum } from "@/core/data/data";
import {
  FDSearchMemberResponseItem,
  GeneralApi,
  MBTreatmentTypeListResponseItem,
} from "@/api";

interface IFdClaimItem {
  checked?: boolean;
  claimCategoryId?: number;
  amount?: number;
  amountType: string;
  claimCategoryName?: string;
  claimCategoryAlt?: string;
}

export const useFdSubmitClaimStore = defineStore({
  id: "fdSubmitClaim",
  state: () => ({
    formValue: {
      fileList: [] as IUploadFileUniqueName[],
      memberNumber: undefined as string | undefined,
      memberName: undefined as string | undefined,
      policyNumber: undefined as string | undefined,
      idCardPassport: undefined as string | undefined,
      gender: undefined as string | undefined,
      phoneNumber: undefined as string | undefined,
      phoneNumberAlt: undefined as string | undefined,
      claimTypeRadio: undefined as string | undefined,
      plannedVisitDate: undefined as string | undefined,
      visitReason: undefined as string | undefined,
      hospitalAdmissionDateTime: undefined as string | undefined,
      plannedDischargeDateTime: undefined as string | undefined,
      totalHours: 0 as number | undefined,
      visitDate: undefined as string | undefined,
      comment: undefined as string | undefined,
      dischargeDateTime: undefined as string | undefined,
      dateOfBirth: undefined as string | undefined,
      memberId: undefined as number | undefined,
      claimItems: [] as IFdClaimItem[],
      totalAmount: 0 as number | undefined,
      inOutPatient: (String(router.currentRoute.value.query?.patientType) ===
      "outPatients"
        ? "OUT"
        : "IN") as string | undefined,
      existingId: undefined as number | undefined,
      aiaNote: undefined as string | undefined,
      memberType: undefined as CardTypeStringEnum | undefined,
      dateOfEvent: undefined as string | undefined,
      treatmentTypeId: undefined as number | undefined,
      invoiceNumber: undefined as string | undefined,
      invoiceDate: undefined as string | undefined,
    },
    dropzoneFile: [] as File[],
    inOut: router.currentRoute.value.query.patientType,
    loading: {
      search: false,
    },
    showing: {
      alertError: false,
    },
    member: {} as FDSearchMemberResponseItem,
    totalAmount: 0,
    bankInfo: {} as IBankInfo,
    isInEditSession: false,
    treatmentTypes: [] as MBTreatmentTypeListResponseItem[],
  }),
  getters: {
    isOutPatients: (state) => state.inOut === "outPatients",
    isInPatients(state): boolean {
      return state.inOut === "inPatients";
    },
    isIndividual(): boolean {
      return this.formValue.memberType === "Individual";
    },
    isCorporate(): boolean {
      return this.formValue.memberType === "Corporate";
    },
    isInitial(): boolean {
      return this.formValue.claimTypeRadio === ClaimTypeStringIdEnum.Initial;
    },
    isFinal(): boolean {
      return this.formValue.claimTypeRadio === ClaimTypeStringIdEnum.Final;
    },
    getTitles() {
      const isOut = this.isOutPatients as boolean;
      return {
        km: isOut ? getTranslate("Out-patient") : getTranslate("In-patient"),
        en: `${isOut ? "OUT" : "IN"} ${"PATIENT"}`,
      };
    },
    isAnyClaimItemChecked(): boolean | undefined {
      return this.formValue.claimItems.some((item) => item.checked);
    },
    isAnyFileUploaded(): boolean {
      return this.formValue.fileList.length > 0;
    },
  },
  actions: {
    setEditSession(inEditSession: boolean) {
      this.isInEditSession = inEditSession;
    },
    async loadTreatmentTypes() {
      await GeneralApi.treatmentType()
        .then((data) => {
          this.treatmentTypes = data.data ?? [];
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
