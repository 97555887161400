import { RouteRecordRaw } from "vue-router";
const prefix = "/fd";
const namePrefix = "fd-";
import permissionJson from "@/assets/data/permissions.json";
import { IPermissions } from "@/interface/IPermissions";
import { fdRouteName } from "@/core/helpers/util";
const permissions: IPermissions = permissionJson;

const frontDeskRouteList: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      return {
        name: namePrefix + "dashboard",
      };
    },
    component: () => import("@/layouts/clinic-front-desk-layout/FdLayout.vue"),
    meta: {
      system: "front-desk",
      // permissions: [permissions.FDSearchMember],
    },
    children: [
      {
        path: prefix + "/dashboard",
        name: namePrefix + "dashboard",
        component: () =>
          import("@/views/clinic-front-desk/member/MemberSearch.vue"),
        meta: { permissions: [permissions.FDSearchMember] },
      },
      {
        path: prefix + "/member",
        name: namePrefix + "member",
        meta: { permissions: [permissions.FDSearchMember] },
        redirect: () => {
          return {
            name: namePrefix + "member-search",
          };
        },
        children: [
          {
            path: ":memberId",
            name: namePrefix + "member-show",
            meta: { permissions: [permissions.FDMemberDetail] },
            component: () =>
              import("@/views/clinic-front-desk/member/MemberShow.vue"),
          },
          {
            path: "history/:memberId",
            name: namePrefix + "member-history",
            meta: { permissions: [permissions.FDClaimHistory] },
            component: () =>
              import("@/views/clinic-front-desk/claim-history/Summary.vue"),
          },
          {
            path: "search",
            name: namePrefix + "member-search",
            meta: { permissions: [permissions.FDSearchMember] },
            component: () =>
              import("@/views/clinic-front-desk/member/MemberSearch.vue"),
          },
        ],
      },
      {
        path: prefix + "/submit-claim",
        name: namePrefix + "submit-claim",
        meta: { permissions: [permissions.FDSubmitClaim] },
        redirect: () => {
          return {
            name: namePrefix + "submit-claim-category",
          };
        },
        children: [
          {
            path: "category",
            name: namePrefix + "submit-claim-category",
            meta: { permissions: [permissions.FDClaimCategories] },
            component: () =>
              import("@/views/clinic-front-desk/submit-claim/FdCategory.vue"),
          },
          {
            path: "form",
            name: fdRouteName("submit-claim-form"),
            meta: { permissions: [permissions.FDSubmitClaim] },
            component: () =>
              import(
                "@/views/clinic-front-desk/submit-claim/FdCreateEditForm.vue"
              ),
          },
          {
            path: "summary-before-submit",
            name: namePrefix + "submit-claim-summary-before-submit",
            meta: { permissions: [permissions.FDSubmitClaim] },
            component: () =>
              import(
                "@/views/clinic-front-desk/submit-claim/FdSummaryBeforeSubmit.vue"
              ),
          },
        ],
      },
      {
        path: prefix + "/membership",
        name: namePrefix + "membership",
        meta: { permissions: [permissions.FDMemberDetail] },
        component: () => import("@/views/apps/account/membership.vue"),
      },
      {
        path: prefix + "/coverage",
        name: namePrefix + "coverage",
        meta: { permissions: [permissions.FDMemberDetail] },
        component: () => import("@/views/apps/account/coverage.vue"),
      },
      {
        path: prefix + "/submit",
        name: namePrefix + "submit",
        meta: { permissions: [permissions.FDSubmitClaim] },
        component: () => import("@/views/apps/claims/form.vue"),
      },
      {
        path: prefix + "/claim-history",
        name: namePrefix + "claim-history",
        meta: { permissions: [permissions.FDClaimHistory] },
        redirect: () => {
          return { name: namePrefix + "claim-history-summary" };
        },
        children: [
          {
            path: "summary",
            name: namePrefix + "claim-history-summary",
            meta: { permissions: [permissions.FDClaimSummary] },
            component: () =>
              import("@/views/clinic-front-desk/claim-history/Summary.vue"),
          },
          {
            path: "history",
            name: namePrefix + "claim-history-history",
            meta: { permissions: [permissions.FDClaimHistory] },
            component: () =>
              import("@/views/clinic-front-desk/claim-history/History.vue"),
          },
          {
            path: "show/:claimHistoryId",
            name: namePrefix + "claim-history-show",
            meta: { permissions: [permissions.FDDetail] },
            component: () =>
              import(
                "@/views/clinic-front-desk/claim-history/ShowClaimDetail.vue"
              ),
          },
          {
            path: "edit/:claimHistoryId",
            name: namePrefix + "claim-history-edit",
            meta: { permissions: [permissions.FDClaimDetailForEdit] },
            component: () =>
              import(
                "@/views/clinic-front-desk/submit-claim/FdCreateEditForm.vue"
              ),
          },
        ],
      },
      {
        path: prefix + "/help",
        name: namePrefix + "help",
        component: () => import("@/views/clinic-front-desk/Help.vue"),
      },
      {
        path: prefix + "/locator-map",
        name: namePrefix + "map",
        component: () => import("@/views/apps/locator/map.vue"),
      },
      {
        path: prefix + "/profile",
        name: namePrefix + "profile",
        component: () =>
          import("@/views/clinic-front-desk/account/ShowProfile.vue"),
      },
      {
        path: prefix + "/notifications",
        name: namePrefix + "notifications",
        component: () =>
          import("@/views/member/notification/GlobalNotification.vue"),
      },
      {
        path: prefix + "/profile-change-password",
        name: namePrefix + "profile-change-password",
        component: () =>
          import("@/views/clinic-front-desk/account/ChangePassword.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    meta: {
      system: "front-desk",
    },
    children: [
      {
        path: `fd/sign-in`,
        name: "fd-sign-in",
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/SignIn.vue`
          ),
      },
      {
        path: `fd/asking-otp`,
        name: "fd-asking-otp",
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/AskingOtp.vue`
          ),
      },
      {
        path: `fd/verify-otp`,
        name: "fd-verify-otp",
        component: () => import(`@/views/member/auth/VerifyOTP.vue`),
      },
      {
        path: `fd/sign-up`,
        name: "fd-sign-up",
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/SignUp.vue`
          ),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/clinic-front-desk-layout/FdLayout.vue"),
    meta: {
      system: "front-desk",
    },
    children: [
      {
        path: `fd/password-reset`,
        name: "fd-password-reset",
        component: () => import(`@/views/ResetPasswordWithPhone.vue`),
      },
      {
        path: `fd/check-your-email`,
        name: "fd-check-your-email",
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/CheckYourEmail.vue`
          ),
      },
      {
        path: `fd/set-your-new-password`,
        name: "fd-set-your-new-password",
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/SetYourNewPassword.vue`
          ),
      },
    ],
  },
  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "fd-404",
    meta: {
      system: "front-desk",
    },
    component: () =>
      import(`@/views/clinic-front-desk/authentication/Error404.vue`),
  },
  {
    path: "/500",
    name: "fd-500",
    meta: {
      system: "front-desk",
    },
    component: () =>
      import(`@/views/clinic-front-desk/authentication/Error500.vue`),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

export default frontDeskRouteList;
