import { defineStore } from "pinia";
import {
  getCurrentTzOffset,
  getTranslate,
  isEnvLocal,
} from "@/core/helpers/custom";
import { IDateErrorMessage, IDateInput, IInput } from "@/interface/IInput";
import {
  CardTypeStringEnum,
  InputDirective,
  InputTypeEnum,
} from "@/core/data/data";
import * as Yup from "yup";
import { IFdMemberSearchTab } from "@/store/fd/fd-member-search/IFdMemberSearch";
import { FDSearchMemberResponseItem, FrontDeskV2Api } from "@/api";
import moment from "moment/moment";
import { IErrorResponse } from "@/interface/errorInterface";
import { ITab } from "@/interface/ITab";

export const useFdMemberSearchStore = defineStore({
  id: "fdSearchMemberStore",
  state: () => ({
    titles: {
      en: "SEARCH MEMBER",
      km: getTranslate("Search member"),
    },
    subTitle: {
      en: "Search for a member to view eligibility, claims and more.",
      km: getTranslate(
        "Search for a member to view eligibility, claims and more"
      ),
    },
    tabHeaders: [
      {
        title: "CORPORATE PLAN",
        href: "#corporate-plan",
        id: 1,
      },
      {
        title: "INDIVIDUAL PLAN",
        href: "#individual-plan",
        id: 2,
      },
    ],
    tabs: {
      // corporatePlan
      1: <IFdMemberSearchTab>{
        name: "corporate-plan",
        confirmed: {
          label: {
            en: "I have sighted the customers Membership Card and their ID Card/Passport and confirm that they are the same person",
            km: getTranslate(
              "I have sighted the customers Membership Card and their ID Card/Passport and confirm that they are the same person"
            ),
          },
          name: "confirmed",
          inputType: InputTypeEnum.Checkbox,
          isConfirmed: false,
        },
        submitButton: {
          label: {
            en: "SEARCH",
            km: getTranslate("Search"),
          },
          name: "corporatePlanSearch",
          inputType: InputTypeEnum.Button,
          isLoading: false,
        },
        isShowSampleCard: true,
        formSection: {
          id: "corporate-plan-form",
          submitMethod: "corporatePlanSearch",
          validationSchema: Yup.object().shape({
            memberNumber: Yup.string()
              .min(8, {
                en: "Must be at least 8 characters.",
                km: "ត្រូវតែមានយ៉ាងហោចណាស់ 8 តួអក្សរ",
              })
              .max(10, {
                en: "Must be at most 10 characters.",
                km: "ត្រូវតែមានយ៉ាងច្រើនបំផុត 10 តួអក្សរ",
              })
              .required({
                en: "Member number is required.",
                km: `${getTranslate("Member Number")} ត្រូវបានទាមទារ`,
              }),
            dateOfBirth: Yup.string().required({
              en: `Date of birth is required.`,
              km: `${getTranslate("Date of Birth")} ត្រូវបានទាមទារ`,
            }),
          }),
          inputFields: [
            <IInput>{
              label: {
                en: "Member Number",
                km: getTranslate("Member Number"),
              },
              name: "memberNumber",
              value: "",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: A000000",
              directives: [InputDirective.allowOnlyNumber(10)],
            },
            <IDateInput>{
              label: {
                en: "Date of Birth",
                km: getTranslate("Date of Birth"),
              },
              name: "dateOfBirth",
              value: "",
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex: 24 August 2022",
              isShowTime: false,
            },
          ],
        },
        type: CardTypeStringEnum.CORPORATE,
      },
      // individualPlan
      2: <IFdMemberSearchTab>{
        name: "individual-plan",
        label: {
          en: "INDIVIDUAL PLAN",
          km: getTranslate("INDIVIDUAL PLAN"),
        },
        value: "individualPlan",
        confirmed: {
          label: {
            en: "I have sighted the customers Membership Card and their ID Card/Passport and confirm that they are the same person",
            km: getTranslate(
              "I have sighted the customers Membership Card and their ID Card/Passport and confirm that they are the same person"
            ),
          },
          name: "confirmed",
          inputType: InputTypeEnum.Checkbox,
          isConfirmed: false,
        },
        submitButton: {
          label: {
            en: "SEARCH",
            km: getTranslate("Search"),
          },
          name: "individualPlanSearch",
          inputType: InputTypeEnum.Button,
          isLoading: false,
        },
        isShowSampleCard: false,
        formSection: {
          id: "individual-plan-form",
          submitMethod: "individualPlanSearch",
          validationSchema: Yup.object().shape({
            policyNumber: Yup.string()
              .min(9, {
                en: "Must be at least 9 characters.",
                km: "",
              })
              .max(10, {
                en: "Must be at most 10 characters.",
                km: "",
              })
              // matches must follow this pattern B000404A09
              .matches(/^[a-zA-Z]{1}[0-9]{6}[a-zA-Z]{1}[0-9]{2}$/, {
                message: {
                  en: "Incorrect Policy Number Format. Please input again",
                  km: "",
                },
              })
              .required({
                en: "Incorrect Policy Number Format. Please input again",
                km: "",
              }),
            dateOfBirth: Yup.string().required().label("Date of Birth"),
          }),
          inputFields: [
            <IInput>{
              label: {
                en: "Policy Number",
                km: getTranslate("Policy Number"),
              },
              name: "policyNumber",
              value: "",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: A12342A00",
              directives: [],
            },
            <IDateInput>{
              label: {
                en: "Date of Birth",
                km: getTranslate("Date of Birth"),
              },
              name: "dateOfBirth",
              value: "",
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex: 24 August 2022",
              isShowTime: false,
            },
          ],
        },
        type: CardTypeStringEnum.INDIVIDUAL,
      },
    },
    formValues: {
      memberNumber: "",
      dateOfBirth: "",
      policyNumber: "",
    },
    errors: {
      isUserNotFound: false,
      isInvalidDateOfBirth: false,
    },
    dateErrorMessage: <IDateErrorMessage>{
      isError: false,
      titles: {
        km: undefined as string | undefined,
        en: undefined as string | undefined,
      },
    },
    list: [] as FDSearchMemberResponseItem[],
    currentTab: undefined as ITab | undefined,
  }),
  getters: {
    getList(state): FDSearchMemberResponseItem[] {
      return state.list;
    },
    isCorporatePlanConfirmed(state): boolean {
      return state.tabs[1].confirmed.isConfirmed;
    },
    isIndividualPlanConfirmed(state): boolean {
      return state.tabs[2].confirmed.isConfirmed;
    },
  },
  actions: {
    setLoading: function (param: CardTypeStringEnum, isLoading = true) {
      switch (param) {
        case CardTypeStringEnum.CORPORATE:
          this.setCorporatePlanLoading(isLoading);
          break;
        case CardTypeStringEnum.INDIVIDUAL:
          this.setIndividualPlanLoading(isLoading);
          break;
      }
    },
    async dynamicMethod(
      value: { cardType: CardTypeStringEnum },
      { resetForm }
    ) {
      const param = value.cardType as CardTypeStringEnum;
      this.resetAllErrors();
      this.setLoading(param);
      if (this.isFormConfirmed(param as CardTypeStringEnum)) {
        await FrontDeskV2Api.searchMember({
          type: param,
          memberNumber:
            param === CardTypeStringEnum.CORPORATE
              ? this.formValues.memberNumber
              : undefined,
          dateOfBirth: moment(this.formValues.dateOfBirth)
            .add(getCurrentTzOffset(), "hours")
            .toISOString(),
          policyNumber:
            param === CardTypeStringEnum.INDIVIDUAL
              ? this.formValues.policyNumber
              : undefined,
        })
          .then((res) => {
            this.setList(res.data);
            resetForm({
              values: {
                cardType: value.cardType,
              },
            });
            this.dateErrorMessage.isError = false;
          })
          .catch((error) => {
            this.setList([]);
            const data = error.data as IErrorResponse;
            switch (data.statusCode) {
              case 708:
                this.setIsUserNotFound(true);
                break;
              // You have entered the wrong date of birth
              case 709:
                this.setIsInvalidDateOfBirth(true);
                this.dateErrorMessage = {
                  isError: true,
                  titles: {
                    en: data.message,
                    km: data.messageAlt,
                  },
                };
                break;
            }
            this.setLoading(param, false);
          });
      }
      this.setLoading(param, false);
    },
    setList(list: FDSearchMemberResponseItem[] = []) {
      this.list = list;
    },
    setCorporatePlanLoading(isLoading: boolean) {
      this.tabs[1].submitButton.isLoading = isLoading;
    },
    setIsUserNotFound(isUserNotFound: boolean) {
      this.errors.isUserNotFound = isUserNotFound;
      this.dateErrorMessage.isError = false;
    },
    resetAllErrors() {
      this.errors.isUserNotFound = false;
      this.errors.isInvalidDateOfBirth = false;
    },
    //check is form confirm by plan type
    isFormConfirmed(planType: CardTypeStringEnum) {
      return planType === CardTypeStringEnum.CORPORATE
        ? this.isCorporatePlanConfirmed
        : this.isIndividualPlanConfirmed;
    },
    setIsInvalidDateOfBirth(isInvalidDateOfBirth: boolean) {
      this.errors.isInvalidDateOfBirth = isInvalidDateOfBirth;
    },
    setIndividualPlanLoading(isLoading: boolean) {
      this.tabs[2].submitButton.isLoading = isLoading;
    },
  },
});
