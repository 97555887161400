import { NavigationGuardNext } from "vue-router";
import { jwtDecodeFromToken } from "@/core/helpers/custom";
import { appRouteName } from "@/core/helpers/util";
import getApiService from "@/core/services/getApiService";

export default async (to, __, next: NavigationGuardNext) => {
  let userPermissions: string[];

  if ((process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0) {
    // if /Api
    const response = await getApiService().get("/GetPermissions");
    userPermissions = response.data;
  } else {
    userPermissions =
      jwtDecodeFromToken()[
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
      ] ?? [];
  }

  // const userPermissions =
  //   jwtDecodeFromToken()[
  //     "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  //   ] ?? [];

  const permissions: string[] = to.meta.permissions as string[];
  if (permissions && permissions.length > 0) {
    const isAllowed = userPermissions.some((p: string) => {
      return permissions.includes(p);
    });

    if (!isAllowed) {
      next({ name: appRouteName("sign-in") });
    }
  }
  next();
};
