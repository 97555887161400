import { IChangePasswordRequest } from "@/interface/IChangePasswordRequest";
import { IFDLoginByUserOTP } from "@/interface/IFDLoginByUserOTP";
import { IMBSetNewPassword } from "@/interface/IMBSetNewPasswordRequest";
import { IMBUpdateProfileRequest } from "@/interface/IMBUpdateProfileRequest";
import { IOTPScreenData } from "@/interface/IOTPScreenData";
import { IUpdateBankInfoRequest } from "@/interface/IUpdateBankInfoRequest";
import { RememberMe } from "@/interface/RememberMeInterface";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

export class LocalStorageServiceClass {
  protected Prefix = "FD_";

  protected TokenKey = "ACCESS_TOKEN";
  SaveToken = (data: string): void => {
    window.localStorage.setItem(this.Prefix + this.TokenKey, data);
  };
  GetToken = (): string | undefined => {
    return (
      window.localStorage.getItem(this.Prefix + this.TokenKey) ?? undefined
    );
  };
  protected SaveOTPScreenDataKey = "OTP_SCREEN_DATA";
  SaveOTPScreenData = (data: IOTPScreenData): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveOTPScreenDataKey,
      JSON.stringify(data)
    );
  };
  GetOTPScreenData = (): IOTPScreenData | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveOTPScreenDataKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveOTPOriginateScreenKey = "OTP_ORIGINATE_SCREEN";
  SaveOTPOriginateScreen = (data: string): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveOTPOriginateScreenKey,
      JSON.stringify(data)
    );
  };
  GetOTPOriginateScreen = (): string | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveOTPOriginateScreenKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveProfileUpdateRequestKey = "PROFILE_UPDATE_REQUEST";
  SaveProfileUpdateRequest = (data: IMBUpdateProfileRequest): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveProfileUpdateRequestKey,
      JSON.stringify(data)
    );
  };
  GetProfileUpdateRequest = (): IMBUpdateProfileRequest | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveProfileUpdateRequestKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveChangePasswordRequestKey = "CHANGE_PASSWORD";
  SaveChangePasswordRequest = async (data: IChangePasswordRequest) => {
    await this.SetLocalStorage(
      this.Prefix + this.SaveChangePasswordRequestKey,
      JSON.stringify(data)
    );
  };
  GetChangePasswordRequest = async (): Promise<
    IChangePasswordRequest | undefined
  > => {
    const data = await this.GetLocalStorage(
      this.Prefix + this.SaveChangePasswordRequestKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveUpdateBankInfoRequestKey = "UPDATE_BANK_INFO";
  SaveUpdateBankInfoRequest = (data: IUpdateBankInfoRequest): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveUpdateBankInfoRequestKey,
      JSON.stringify(data)
    );
  };
  GetUpdateBankInfoRequest = (): IUpdateBankInfoRequest | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveUpdateBankInfoRequestKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveResetPasswordRequestKey = "RESET_PASSWORD";
  SaveResetPasswordRequest = (data: IMBSetNewPassword): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveResetPasswordRequestKey,
      JSON.stringify(data)
    );
  };
  GetResetPasswordRequest = (): IMBSetNewPassword | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveResetPasswordRequestKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveSetNewPasswordRequestKey = "SET_NEW_PASSWORD";
  SaveSetNewPasswordRequest = (data: IMBSetNewPassword): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveSetNewPasswordRequestKey,
      JSON.stringify(data)
    );
  };
  GetSetNewPasswordRequest = (): IMBSetNewPassword | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveSetNewPasswordRequestKey
    );
    return JSON.parse(data ?? "");
  };
  protected SaveLoginByUserOTPRequestKey = "LOGIN_BY_USER_OTP";
  SaveLoginByUserOTPRequest = (data: IFDLoginByUserOTP): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveLoginByUserOTPRequestKey,
      JSON.stringify(data)
    );
  };
  GetLoginByUserOTPRequest = (): IFDLoginByUserOTP | undefined => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveLoginByUserOTPRequestKey
    );
    return JSON.parse(data ?? "");
  };

  protected SaveNewRememberMeKey = "SET_REMEMBER_ME";
  SaveNewRememberMe = (data: RememberMe): void => {
    window.localStorage.setItem(
      this.Prefix + this.SaveNewRememberMeKey,
      JSON.stringify(data)
    );
  };
  GetRememberMe = (): RememberMe => {
    const data = window.localStorage.getItem(
      this.Prefix + this.SaveNewRememberMeKey
    );
    return data ? JSON.parse(data) : {};
  };
  ClearRememberMe = () => {
    window.localStorage.removeItem(this.Prefix + this.SaveNewRememberMeKey);
  };

  ClearToken = (): void => {
    const getCurrentToken = window.localStorage.getItem(
      this.Prefix + this.TokenKey
    );
    if (getCurrentToken) {
      if (this.Prefix === "MB_") {
        store
          .dispatch(Actions.MEMBER_LOGOUT)
          .then(() => console.info("Log out!"));
      }
    }

    window.localStorage.removeItem(this.Prefix + this.TokenKey);
  };
  ClearOtpScreenData = (): void => {
    window.localStorage.removeItem(this.Prefix + this.SaveOTPScreenDataKey);
    window.localStorage.removeItem(
      this.Prefix + this.SaveOTPOriginateScreenKey
    );
    window.localStorage.removeItem(
      this.Prefix + this.SaveLoginByUserOTPRequestKey
    );
  };

  SetLocalStorage = async (key: string, value: string) => {
    if ((process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0) {
      // if /Api
      await ApiService.post("/SetSession", {
        key: key,
        value: value,
      });
    } else {
      // window.localStorage.setItem(this.Prefix + key, value);
      // console.log("key", key);
      // i dont know br is not working in normal way
      if (key === "BR_CHANGE_PASSWORD") {
        window.localStorage.setItem(key, value);
      } else {
        window.localStorage.setItem(this.Prefix + key, value);
      }
    }
  };

  GetLocalStorage = async (key: string): Promise<string | undefined> => {
    if ((process.env.VUE_APP_API_URL?.indexOf("/") ?? -1) == 0) {
      // if /Api
      const response = await ApiService.query("/GetSession", {
        params: {
          key: key,
        },
      });
      return response.data;
    } else {
      return window.localStorage.getItem(key) ?? undefined;
    }
  };
}

const LocalStorageService = new LocalStorageServiceClass();
export default LocalStorageService;
