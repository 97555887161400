import { defineStore } from "pinia";
import { getIconLinkPhaseV2, getTranslate } from "@/core/helpers/custom";
import { CardTypeStringEnum, InOutEnum } from "@/core/data/data";
import router from "@/router";
import { fdRouteName } from "@/core/helpers/util";

export interface IFdCategoryCardData {
  main: string;
  mainKm: string;
  sub?: string;
  subKm?: string;
  type: CardTypeStringEnum | InOutEnum;
  icon?: string;
}

export enum FdClaimCategoryLevelEnum {
  PatientType = 1,
  CardType = 2,
}

export const useFdSubmitClaimCategoryStore = defineStore({
  id: "fdSubmitClaimCategory",
  state: () => ({
    titles: {
      en: "SUBMIT CLAIM",
      km: getTranslate("SUBMIT CLAIM"),
    },
    cardDataLevel1: {
      numberOfPendingClaim: <IFdCategoryCardData>{
        main: "Out-patient ",
        mainKm: getTranslate("Out-patient"),
        sub: "Cashless",
        subKm: getTranslate("Cashless"),
        type: InOutEnum.Out,
        icon: getIconLinkPhaseV2("Out_patient.svg"),
      },
      numberOfApprovedClaim: <IFdCategoryCardData>{
        main: "In-patient",
        mainKm: getTranslate("In-patient"),
        sub: "Cashless",
        subKm: getTranslate("Cashless"),
        type: InOutEnum.In,
        icon: getIconLinkPhaseV2("In-patient.svg"),
      },
    },
    cardDataLevel2: {
      IndividualPolicy: <IFdCategoryCardData>{
        main: "Individual Policy",
        mainKm: "",
        type: CardTypeStringEnum.INDIVIDUAL,
        icon: getIconLinkPhaseV2("IndividualPolicy_icon.svg"),
      },
      CorporatePolicy: <IFdCategoryCardData>{
        main: "Corporate Policy",
        mainKm: "",
        type: CardTypeStringEnum.CORPORATE,
        icon: getIconLinkPhaseV2("CorporatePolicy_icon.svg"),
      },
    },
    level: 1,
    policyType: undefined as CardTypeStringEnum | undefined,
    patientType: undefined as InOutEnum | undefined,
    memberId: undefined as number | undefined,
  }),
  getters: {
    isLevel1(): boolean {
      return this.level === FdClaimCategoryLevelEnum.PatientType;
    },
    isLevel2(): boolean {
      return this.level === FdClaimCategoryLevelEnum.CardType;
    },
  },
  actions: {
    setPolicyType(type: CardTypeStringEnum) {
      this.policyType = type;
    },

    patientLevelClick(type: InOutEnum) {
      if (this.policyType) {
        this.patientType = type;
        this.routeToForm();
        return;
      }

      this.level = FdClaimCategoryLevelEnum.CardType;
      this.patientType = type;

      if (type === InOutEnum.Out) {
        this.setTitle({ en: "OUT-PATIENT", km: getTranslate("OUT-PATIENT") });
      } else {
        this.setTitle({ en: "IN-PATIENT", km: getTranslate("IN-PATIENT") });
      }
    },
    setTitle({ en, km }: { en: string; km: string }) {
      this.titles.en = en;
      this.titles.km = km;
    },
    setLevel(level: number) {
      this.level = level;
    },
    setMemberId(id: number) {
      this.memberId = id;
    },
    routeToForm() {
      router
        .push({
          name: fdRouteName("submit-claim-form"),
          query: {
            policyType: this.policyType,
            patientType:
              this.patientType === "OUT" ? "outPatients" : "inPatients",
            resetPinia: "yes",
            memberId: router.currentRoute.value.query.memberId,
          },
        })
        .then();
    },
    routeToFormWithPolicyType(type: CardTypeStringEnum) {
      this.setPolicyType(type);
      this.routeToForm();
    },
    backCondition() {
      if (this.isLevel2) {
        history.go(1);
        this.setLevel(1);
        this.policyType = undefined;
        this.titles = {
          en: "SUBMIT CLAIM",
          km: getTranslate("SUBMIT CLAIM"),
        };
      }
    },
  },
});
