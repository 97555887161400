import permissionJson from "@/assets/data/permissions.json";
import { mbRouteName, mbRoutePath } from "@/core/helpers/util";
import { IPermissions } from "@/interface/IPermissions";

const prefix = "/mb";
const namePrefix = "mb-";
const permissions: IPermissions = permissionJson;

export default [
  {
    path: "/",
    redirect: prefix + "/policy",
    component: () => import("@/layouts/member-layout/MemberLayout.vue"),
    children: [
      {
        path: prefix + "/policy",
        name: namePrefix + "policy-index",
        meta: { permissions: [permissions.MBDetail] },
        redirect: prefix + "/policy/view",
        component: () => import("@/views/member/profile/Index.vue"),
        children: [
          {
            path: prefix + "/policy/view",
            name: namePrefix + "policy",
            meta: { permissions: [permissions.MBDetail] },
            component: () => import("@/views/member/profile/ViewPolicy.vue"),
          },
          {
            path: mbRoutePath("policy/view-benefit"),
            name: mbRouteName("policy-view-benefit"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () =>
              import("@/views/member/profile/v2/ViewBenefit.vue"),
          },
          {
            path: "view-payment",
            name: mbRouteName("policy-view-payment"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () => import("@/views/member/payment/PaymentIndex.vue"),
          },
          {
            path: "view-payment-detail",
            name: mbRouteName("policy-view-payment-detail"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () => import("@/views/member/payment/PaymentDetail.vue"),
          },
          {
            path: "view-documents",
            name: mbRouteName("policy-view-documents"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () => import("@/views/member/document/DocumentView.vue"),
          },
          {
            path: "view-exclusions",
            name: mbRouteName("policy-view-exclusions"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () =>
              import("@/views/member/profile/v2/ViewExclusions.vue"),
          },
          {
            path: "beneficiary",
            name: mbRouteName("policy-beneficiary"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            redirect: () => {
              return { name: mbRouteName("policy-beneficiary-list") };
            },
            children: [
              {
                path: "create",
                name: mbRouteName("policy-beneficiary-create"),
                meta: { permissions: [permissions.MBPolicyBenefit] },
                component: () =>
                  import("@/views/member/beneficiary/BeneficiaryForm.vue"),
              },
              {
                path: "list",
                name: mbRouteName("policy-beneficiary-list"),
                meta: { permissions: [permissions.MBPolicyBenefit] },
                component: () =>
                  import("@/views/member/beneficiary/BeneficiaryList.vue"),
              },
              {
                path: ":beneficiaryId/show",
                name: mbRouteName("policy-beneficiary-show"),
                meta: { permissions: [permissions.MBPolicyBenefit] },
                component: () =>
                  import("@/views/member/beneficiary/BeneficiaryShow.vue"),
              },
              {
                path: "edit",
                name: mbRouteName("policy-beneficiary-edit"),
                meta: { permissions: [permissions.MBPolicyBenefit] },
                component: () =>
                  import("@/views/member/beneficiary/BeneficiaryEdit.vue"),
              },
              {
                path: ":beneficiaryId/edit",
                name: mbRouteName("policy-beneficiary-edit-by-id"),
                meta: { permissions: [permissions.MBPolicyBenefit] },
                component: () =>
                  import("@/views/member/beneficiary/BeneficiaryForm.vue"),
              },
            ],
          },
        ],
      },
      {
        path: mbRoutePath("active"),
        name: mbRouteName("active-index"),
        meta: { permissions: [permissions.MBClaimHistory] },
        redirect: () => {
          return { name: mbRouteName("active-view") };
        },
        children: [
          {
            path: "view",
            name: mbRouteName("active-view"),
            meta: { permissions: [permissions.MBDetail] },
            component: () => import("@/views/member/active/ActiveIndex.vue"),
          },
          {
            path: ":accommodationId/accommodation",
            name: mbRouteName("accommodation-detail"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () =>
              import("@/views/member/active/AccommodationDetail.vue"),
          },
        ],
      },
      {
        path: prefix + "/claim-history",
        name: namePrefix + "claim-history",
        meta: { permissions: [permissions.MBClaimHistory] },
        redirect: () => {
          return { name: "mb-claim-history-history" };
        },
        children: [
          {
            path: "history",
            name: namePrefix + "claim-history-history",
            meta: { permissions: [permissions.MBClaimHistory] },
            component: () =>
              import("@/views/member/claim-history/MemberClaimHistory.vue"),
          },
          {
            path: "show/:claimHistoryId",
            name: namePrefix + "claim-history-show",
            meta: { permissions: [permissions.MBDetail] },
            component: () =>
              import(
                "@/views/member/claim-history/MemberClaimHistoryDetail.vue"
              ),
          },
        ],
      },
      {
        path: mbRoutePath("submit-claim"),
        name: mbRouteName("submit-claim"),
        meta: { permissions: [permissions.MBSubmitClaim] },
        redirect: () => {
          return {
            name: namePrefix + "submit-claim-category",
          };
        },
        children: [
          {
            path: "category",
            name: mbRouteName("submit-claim-category"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/CategoryView.vue"),
          },
          {
            path: "form",
            name: namePrefix + "submit-claim-form",
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/clinic-front-desk/submit-claim/FdCreateEditForm.vue"
              ),
          },
          {
            path: "form-general-consultation",
            name: namePrefix + "submit-claim-general-consultation",
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/GeneralConsulation.vue"),
          },
          {
            path: "form-general-consultation/:claimHistoryId/edit",
            name: namePrefix + "submit-claim-general-consultation-edit",
            meta: { permissions: [permissions.MBClaimDetailForEdit] },
            component: () =>
              import("@/views/member/submit-claim/form/GeneralConsulation.vue"),
          },
          {
            path: "form-reimbursement",
            name: namePrefix + "submit-claim-reimbursement",
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/MbReimbursement.vue"),
          },
          {
            path: "form-critical-illness",
            name: mbRouteName("submit-claim-critical-illness"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/CriticalIllness.vue"),
          },
          {
            path: "form-critical-illness/:claimHistoryId/edit",
            name: mbRouteName("submit-claim-critical-illness-edit"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/CriticalIllness.vue"),
          },
          {
            path: "form-accidental-burn",
            name: mbRouteName("submit-claim-accidental-burn"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/AccidentalBurn.vue"),
          },
          {
            path: "form-accidental-burn/:claimHistoryId/edit",
            name: mbRouteName("submit-claim-accidental-burn-edit"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import("@/views/member/submit-claim/form/AccidentalBurn.vue"),
          },
          {
            path: "form-total-and-permanent-dismemberment",
            name: mbRouteName("submit-claim-total-and-permanent-dismemberment"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/form/TotalAndPermanentDismemberment.vue"
              ),
          },
          {
            path: "form-total-and-permanent-dismemberment/:claimHistoryId/edit",
            name: mbRouteName(
              "submit-claim-total-and-permanent-dismemberment-edit"
            ),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/form/TotalAndPermanentDismemberment.vue"
              ),
          },
          {
            path: "form-hospitalization-allowance",
            name: mbRouteName("submit-claim-hospitalization-allowance"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/form/HospitalizationAllowance.vue"
              ),
          },
          {
            path: "form-hospitalization-allowance/:claimHistoryId/edit",
            name: mbRouteName("submit-claim-hospitalization-allowance-edit"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/form/HospitalizationAllowance.vue"
              ),
          },
          {
            path: "form-reimbursement/:claimHistoryId/edit",
            name: namePrefix + "submit-claim-reimbursement-edit",
            meta: { permissions: [permissions.MBClaimDetailForEdit] },
            component: () =>
              import("@/views/member/submit-claim/form/MbReimbursement.vue"),
          },
          {
            path: "summary-before-submit",
            name: namePrefix + "submit-claim-summary-before-submit",
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/before-submit/medcare/SummaryBeforeSubmit.vue"
              ),
          },
          {
            path: "life-claim-summary-before-submit",
            name: mbRouteName("life-claim-summary-before-submit"),
            meta: { permissions: [permissions.MBSubmitClaim] },
            component: () =>
              import(
                "@/views/member/submit-claim/before-submit/life-claim/LifeClaimBeforeSubmit.vue"
              ),
          },
        ],
      },
      {
        path: mbRoutePath("service-request"),
        name: mbRouteName("service-request-history"),
        meta: { permissions: [permissions.MBClaimHistory] },
        redirect: () => {
          return { name: mbRouteName("service-request-history-list") };
        },
        children: [
          {
            path: "list",
            name: mbRouteName("service-request-history-list"),
            meta: { permissions: [permissions.MBDetail] },
            component: () =>
              import("@/views/member/service-request/ServiceRequestList.vue"),
          },
          {
            path: ":serviceRequestHistoryId/show",
            name: mbRouteName("service-request-history-show"),
            meta: { permissions: [permissions.MBPolicyBenefit] },
            component: () =>
              import("@/views/member/service-request/ServiceRequestShow.vue"),
          },
        ],
      },
      {
        path: prefix + "/help",
        name: namePrefix + "help",
        component: () => import("@/views/clinic-front-desk/Help.vue"),
      },
      {
        path: prefix + "/notifications",
        name: namePrefix + "notifications",
        component: () =>
          import("@/views/member/notification/GlobalNotification.vue"),
      },
      {
        path: mbRoutePath("profile"),
        name: mbRouteName("profile"),
        component: () => import("@/views/member/profile/MemberProfile.vue"),
      },
      {
        path: mbRoutePath("terms-and-conditions/:claimTypeId"),
        name: mbRouteName("terms-and-conditions"),
        component: () => import("@/views/TermAndCondition.vue"),
      },
      {
        path: mbRoutePath("profile-change-password"),
        name: mbRouteName("profile-change-password"),
        component: () =>
          import("@/views/clinic-front-desk/account/ChangePassword.vue"),
      },
      {
        path: mbRoutePath("profile-edit"),
        name: mbRouteName("edit-profile"),
        component: () =>
          import("@/views/member/profile/v2/EditProfileMember.vue"),
      },
      {
        path: mbRoutePath("update-bank-info"),
        name: mbRouteName("update-bank-info"),
        component: () => import("@/views/member/profile/UpdateBankInfo.vue"),
      },
      {
        path: mbRoutePath("request-otp"),
        name: mbRouteName("request-otp"),
        component: () => import("@/views/member/auth/RequestOTP.vue"),
      },
      {
        path: prefix + "/password-reset",
        name: namePrefix + "password-reset",
        meta: {},
        component: () => import("@/views/ResetPasswordWithPhone.vue"),
      },
      {
        path: mbRoutePath(`set-your-new-password`),
        name: mbRouteName("set-your-new-password"),
        component: () =>
          import(
            `@/views/clinic-front-desk/authentication/basic-flow/SetYourNewPassword.vue`
          ),
      },
    ],
  },
  {
    path: prefix,
    component: () => import("@/layouts/member-layout/AuthLayout.vue"),
    children: [
      {
        path: "sign-in",
        name: mbRouteName("sign-in"),
        meta: {},
        component: () => import("@/views/member/auth/SignIn.vue"),
      },
      {
        path: "verify-otp",
        name: mbRouteName("verify-otp"),
        meta: {},
        component: () => import("@/views/member/auth/VerifyOTP.vue"),
      },
    ],
  },
  {
    path: prefix,
    component: () => import("@/layouts/member-layout/AuthLayoutDefault.vue"),
    children: [
      {
        path: "register",
        name: mbRouteName("register"),
        meta: {},
        component: () =>
          import("@/views/member/auth/register/RegisterMember.vue"),
      },
      {
        path: prefix + "/create-account",
        name: mbRouteName("createaccount"),
        meta: {},
        component: () => import("@/views/member/auth/CreateAccount.vue"),
      },
      {
        path: prefix + "/welcome-aia",
        name: namePrefix + "welcomeaia",
        meta: {},
        component: () => import("@/views/member/auth/WelcomeAIA.vue"),
      },
      {
        path: "do-not-see-your-policy",
        name: mbRouteName("do-not-see-your-policy"),
        component: () =>
          import("@/views/member/auth/register/DoNotSeeYourPolicy.vue"),
      },
    ],
  },
];
