import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { App } from "vue";
import { Router } from "vue-router";
import { appRouteName } from "../helpers/util";
import getLocalStorageService from "./getLocalStorageService";
// import useNotyf from "@/composable/useNotyf";

export class ApiServiceClass {
  /**
   * @description property to share vue instance
   */
  public vueInstance?: App;
  public router?: Router;
  public instance?: AxiosInstance;

  public init(app: App<Element>, router: Router) {
    this.vueInstance = app;
    this.router = router;
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
    });

    const routerObj = this.router;
    this.instance.interceptors.request.use((config) => {
      config.headers["Platform"] = "web";
      return config;
    });
    this.instance.interceptors?.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const code = error?.response?.status;

        if (code == 401 || error.response === undefined) {
          routerObj.push({
            name: appRouteName("sign-in"),
          });
        }

        return Promise.reject(error?.response);
      }
    );
  }

  public query(
    resource: string,
    params: AxiosRequestConfig,
    isAuth = true,
    header = {}
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = {
      headers: {},
      responseType: header["isBinary"] ? "arraybuffer" : "json",
      params: params.params,
    };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.get(resource, config);
  }

  /**
   * @description send the GET HTTP request
   * @param resource: string
   * @param slug: string
   * @param isAuth
   * @returns Promise<AxiosResponse>
   */
  public get(
    resource: string,
    slug = "" as string,
    isAuth = true
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = { headers: {} };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.get(`${resource}/${slug}`, config);
  }

  public post(
    resource: string,
    params: any,
    isAuth = true
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = { headers: {} };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.post(`${resource}`, params, config);
  }

  public update(
    resource: string,
    slug: string,
    params: AxiosRequestConfig,
    isAuth = true
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = { headers: {} };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.put(`${resource}/${slug}`, params, config);
  }

  public put(
    resource: string,
    params: AxiosRequestConfig,
    isAuth = true
  ): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = { headers: {} };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.put(`${resource}`, params, config);
  }

  public delete(resource: string, isAuth = true): Promise<AxiosResponse> {
    const config: AxiosRequestConfig = { headers: {} };
    if (isAuth) {
      const token = getLocalStorageService()?.GetToken();
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    }
    return this.instance!.delete(resource, config);
  }

  public addAuth(config: AxiosRequestConfig) {
    const token = getLocalStorageService()?.GetToken();
    if (config.headers) {
      if (token && config.headers) {
        config.headers = {
          Authorization: `Bearer ${token}`,
        };
      }
    } else {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
  }
}

const ApiService = new ApiServiceClass();
export default ApiService;
