import ApiService from "@/core/services/ApiService";
import router from "@/router";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
function getDefaultDataAccountData() {
  const data = window.localStorage.getItem("create_account_data");
  if (data) return JSON.parse(data ? data : "");
}

@Module
export default class MemberModule extends VuexModule {
  responseOTP = {};
  createAccountData = getDefaultDataAccountData();

  get getResponseOTP() {
    return this.responseOTP;
  }
  get getDataCreateAccount() {
    return this.createAccountData;
  }

  @Mutation
  [Mutations.CLEAR_DATA_NEW_PASSOWRD]() {
    this.createAccountData = null;
    window.localStorage.removeItem("create_account_data");
  }

  @Mutation
  [Mutations.SET_OTP_MEMEBER](payload) {
    this.responseOTP = payload;
  }
  @Mutation
  [Mutations._OPEN_SET_NEW_PASSOWRD](payload) {
    this.createAccountData = payload;
    window.localStorage.setItem("create_account_data", JSON.stringify(payload));
  }

  @Action({ rawError: true })
  [Actions.OPEN_SET_NEW_PASSOWRD](payload) {
    this.context.commit(Mutations._OPEN_SET_NEW_PASSOWRD, payload);
  }

  @Action({ rawError: true })
  [Actions.OPEN_OTP_MEMEBER](payload) {
    this.context.commit(Mutations.SET_OTP_MEMEBER, payload);
    router.push("/mb/verify-otp");
  }
}
