import { routeCheck } from "../helpers/util";
import ApiService from "./ApiService";
import ApiServiceCX, { ApiServiceClass } from "./ApiService";
import ApiServiceMember from "./ApiServiceMember";

export default function getApiService(): ApiServiceClass {
  if (routeCheck.isCX()) {
    return ApiServiceCX;
  } else if (routeCheck.isFD()) {
    return ApiService;
  } else if (routeCheck.isMB()) {
    return ApiServiceMember;
  } else if (routeCheck.isHR()) {
    return ApiServiceMember;
  }
  return ApiService;
}
