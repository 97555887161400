import { brRouteName, brRoutePath } from "@/core/helpers/util";
import { RouteRecordRaw } from "vue-router";
import permissionJson from "@/assets/data/permissions.json";
import { IPermissions } from "@/interface/IPermissions";

const prefix = "/br";
const permissions: IPermissions = permissionJson;

const frontDeskRouteList: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      return {
        name: brRouteName("policy-holder"),
      };
    },
    component: () => import("@/layouts/br-layout/Layout.vue"),
    meta: {
      system: "br",
      // permissions: [permissions.HRPolicyBenefit],
    },
    children: [
      {
        path: brRoutePath("policy-holder"),
        name: brRouteName("policy-holder"),
        redirect: () => {
          return { name: brRouteName("policy-holder-listing") };
        },
        children: [
          {
            path: "listing",
            name: brRouteName("policy-holder-listing"),
            meta: { permissions: [permissions.BrokerListPolicyHolder] },
            component: () =>
              import("@/views/br/policy-holder/PolicyHolderListing.vue"),
          },
          {
            path: "detail/policy-holder/:id",
            name: brRouteName("policy-holder-detail"),
            meta: { permissions: [permissions.BrokerListPolicyHolder] },
            component: () =>
              import("@/views/br/policy-holder/PolicyDetail.vue"),
          },
          {
            path: "claim-history/:id",
            name: brRouteName("policy-holder-claim-history-by-id"),
            meta: { permissions: [permissions.BrokerClaimHistory] },
            component: () =>
              import("@/views/br/claim/ClaimHistoryByPolicyHolder.vue"),
          },
        ],
      },
      {
        path: brRoutePath("notifications"),
        name: brRouteName("notifications"),
        component: () =>
          import("@/views/member/notification/GlobalNotification.vue"),
      },
      {
        path: brRoutePath("profile"),
        name: brRouteName("profile"),
        component: () =>
          import("@/views/clinic-front-desk/account/ShowProfile.vue"),
      },
      {
        path: brRoutePath("profile-change-password"),
        name: brRouteName("profile-change-password"),
        component: () =>
          import("@/views/clinic-front-desk/account/ChangePassword.vue"),
      },
      {
        path: brRoutePath("request-otp"),
        name: brRouteName("request-otp"),
        component: () => import("@/views/member/auth/RequestOTP.vue"),
      },
      {
        path: brRoutePath("policy"),
        name: brRouteName("policy"),
        meta: { permissions: [permissions.HRPolicyBenefit] },
        component: () => import("@/views/hr/policy/Index.vue"),
      },
      {
        path: brRoutePath("help"),
        name: brRouteName("help"),
        component: () => import("@/views/clinic-front-desk/Help.vue"),
      },
      {
        path: brRoutePath("policy-holder/:id/view-benefit"),
        name: brRouteName("view-benefit"),
        meta: { permissions: [permissions.BrokerBenefitDetial] },
        component: () => import("@/views/br/policy-holder/ViewBenefit.vue"),
      },
      {
        path: brRoutePath("policy-holder/:id/company-profile"),
        name: brRouteName("company-profile"),
        meta: { permissions: [permissions.BrokerCompanyProfile] },
        component: () =>
          import("@/views/br/company-profile/CompanyProfile.vue"),
      },
      {
        path: brRoutePath("policy-holder/:id/service-request"),
        name: brRouteName("service-request"),
        meta: { permissions: [permissions.BrokerServiceLogs] },
        redirect: () => {
          return brRouteName("service-request-listing");
        },
        children: [
          {
            path: "listing",
            name: brRouteName("service-request-listing"),
            meta: { permissions: [permissions.BrokerServiceLogs] },
            component: () =>
              import("@/views/br/service-request/ServiceRequestList.vue"),
          },
          {
            path: "detail/:serviceRequestId",
            name: brRouteName("service-request-detail"),
            meta: { permissions: [permissions.BrokerServiceLogs] },
            component: () =>
              import("@/views/br/service-request/ServiceRequestShow.vue"),
          },
        ],
      },
      {
        path: brRoutePath("policy-holder/:id/document"),
        name: brRouteName("document"),
        meta: { permissions: [permissions.BrokerPolicyHolderDocuments] },
        component: () => import("@/views/br/document/BrDocument.vue"),
      },
      {
        path: brRoutePath("policy-holder/:id/member"),
        name: brRouteName("member"),
        redirect: () => {
          return brRouteName("member-listing");
        },
        children: [
          {
            path: "listing",
            name: brRouteName("member-listing"),
            meta: { permissions: [permissions.BrokerMembers] },
            component: () => import("@/views/br/member/MemberListing.vue"),
          },
          {
            path: "terminate-member",
            name: brRouteName("member-terminate"),
            meta: { permissions: [permissions.BrokerTerminateMember] },
            component: () => import("@/views/br/member/TerminateMember.vue"),
          },
          // {
          //   path: "create-member",
          //   name: hrRouteName("create-member"),
          //   meta: { permissions: [permissions.HRMembers] },
          //   component: () => import("@/views/hr/member/CreateMember.vue"),
          // },
          {
            path: "wallet-member",
            name: brRouteName("wallet-member"),
            meta: { permissions: [permissions.BrokerMembers] },
            component: () => import("@/views/br/member/WalletMember.vue"),
          },
          {
            path: "terminate-member-success",
            name: brRouteName("member-terminate-success"),
            meta: { permissions: [permissions.BrokerTerminateMember] },
            component: () =>
              import("@/views/hr/member/TerminateMemberSuccess.vue"),
          },
          {
            path: "profile/:memberId",
            name: brRouteName("member-profile"),
            meta: { permissions: [permissions.BrokerMemberDetail] },
            component: () => import("@/views/br/member/MemberProfile.vue"),
          },
          // {
          //   path: "update-bank-info/:memberId",
          //   name: hrRouteName("member-update-bank-info"),
          //   meta: { permissions: [permissions.HRUpdateMemberBankInfo] },
          //   component: () => import("@/views/hr/member/UpdateBankInfo.vue"),
          // },
          // {
          //   path: "profile/edit/:memberId",
          //   name: hrRouteName("member-change-profile-detail"),
          //   meta: { permissions: [permissions.HRUpdateMemberProfile] },
          //   component: () =>
          //     import("@/views/hr/member/UpdateProfileDetail.vue"),
          // },
          {
            path: "claim-history/:memberId",
            name: brRouteName("member-claim-history-by-member"),
            meta: { permissions: [permissions.BrokerClaimHistory] },
            component: () =>
              import("@/views/br/member/ClaimHistoryByMember.vue"),
          },
          {
            path: "view-benefit/:memberId",
            name: brRouteName("view-benefit-by-member"),
            meta: { permissions: [permissions.BrokerMemberPolicyBenefit] },
            component: () => import("@/views/br/member/v2/ViewBenefit.vue"),
          },
          {
            path: "claim-detail/:claimHistoryId",
            name: brRouteName("member-claim-detail-member"),
            meta: { permissions: [permissions.BrokerClaimHistory] },
            component: () => import("@/views/br/claim/ShowHistoryDetail.vue"),
          },
          {
            path: "submit-claim",
            name: brRouteName("submit-claim"),
            meta: { permissions: [permissions.BrokerSubmitClaim] },
            children: [
              {
                path: "form-reimbursement",
                name: brRouteName("submit-claim-reimbursement"),
                meta: { permissions: [permissions.BrokerSubmitClaim] },
                component: () => import("@/views/br/claim/BrReimbursement.vue"),
              },
              {
                path: "summary-before-submit",
                name: brRouteName("submit-claim-summary-before-submit"),
                meta: { permissions: [permissions.BrokerSubmitClaim] },
                component: () =>
                  import("@/views/br/claim/SummaryBeforeSubmit.vue"),
              },
              {
                path: ":claimId/form-reimbursement-edit",
                name: brRouteName("submit-claim-reimbursement-edit"),
                meta: { permissions: [permissions.BrokerSubmitClaim] },
                component: () => import("@/views/br/claim/BrReimbursement.vue"),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: prefix,
    component: () => import("@/layouts/br-layout/AuthLayout.vue"),
    children: [
      {
        path: "sign-in",
        name: brRouteName("sign-in"),
        meta: {},
        component: () => import("@/views/br/auth/SignIn.vue"),
      },
      {
        path: "asking-otp",
        name: brRouteName("asking-otp-sms"),
        meta: {},
        component: () => import("@/views/br/auth/AskingOTPSMS.vue"),
      },
      {
        path: "verify-otp",
        name: brRouteName("verify-otp"),
        meta: {},
        component: () => import("@/views/br/auth/VerifyOTPSMS.vue"),
      },
      {
        path: "verify-otp-email",
        name: brRouteName("verify-otp-email"),
        meta: {},
        component: () => import("@/views/br/auth/VerifyOTPEmail.vue"),
      },
      {
        path: "verify-otp-sms",
        name: brRouteName("verify-otp-sms"),
        meta: {},
        component: () => import("@/views/br/auth/VerifyOTPSMS.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/br-layout/Layout.vue"),
    meta: {
      system: "hr",
    },
    children: [
      {
        path: brRoutePath("password-reset"),
        name: brRouteName("password-reset"),
        component: () => import(`@/views/br/auth/ForgetYourPassword.vue`),
      },
      {
        path: brRoutePath("check-your-email"),
        name: brRouteName("check-your-email"),
        component: () => import(`@/views/br/auth/CheckYourEmail.vue`),
      },
      {
        path: brRoutePath("set-your-new-password"),
        name: brRouteName("set-your-new-password"),
        component: () => import(`@/views/br/auth/SetYourNewPassword.vue`),
      },
    ],
  },
];

export default frontDeskRouteList;
