import { appRouteName, hrRouteName, hrRoutePath } from "@/core/helpers/util";
import { RouteRecordRaw } from "vue-router";
import permissionJson from "@/assets/data/permissions.json";
import { IPermissions } from "@/interface/IPermissions";

const prefix = "/hr";
const permissions: IPermissions = permissionJson;

const frontDeskRouteList: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: () => {
      return {
        name: hrRouteName("policy"),
      };
    },
    component: () => import("@/layouts/hr-layout/Layout.vue"),
    meta: {
      system: "hr",
      // permissions: [permissions.HRPolicyBenefit],
    },
    children: [
      {
        path: hrRoutePath("profile"),
        name: hrRouteName("profile"),
        component: () =>
          import("@/views/clinic-front-desk/account/ShowProfile.vue"),
      },
      {
        path: hrRoutePath("profile-change-password"),
        name: hrRouteName("profile-change-password"),
        component: () =>
          import("@/views/clinic-front-desk/account/ChangePassword.vue"),
      },
      {
        path: hrRoutePath("request-otp"),
        name: hrRouteName("request-otp"),
        component: () => import("@/views/member/auth/RequestOTP.vue"),
      },
      {
        path: hrRoutePath("notifications"),
        name: hrRouteName("notifications"),
        component: () =>
          import("@/views/member/notification/GlobalNotification.vue"),
      },
      {
        path: hrRoutePath("help"),
        name: hrRouteName("help"),
        component: () => import("@/views/clinic-front-desk/Help.vue"),
      },
      {
        path: hrRoutePath("document"),
        name: hrRouteName("document"),
        meta: { permissions: [permissions.HRPolicyHolderDocuments] },
        component: () => import("@/views/hr/document/Document.vue"),
      },
      {
        path: hrRoutePath("policy"),
        name: hrRouteName("policy"),
        meta: { permissions: [permissions.HRPolicyBenefit] },
        redirect: () => {
          return { name: hrRouteName("policy-view") };
        },
        component: () => import("@/views/hr/policy/Index.vue"),
        children: [
          {
            path: "view",
            name: hrRouteName("policy-view"),
            component: () => import("@/views/hr/policy/Policy.vue"),
            meta: { permissions: [permissions.HRPolicyInfo] },
          },
          {
            path: "view-benefit",
            name: hrRouteName("policy-view-benefit"),
            meta: { permissions: [permissions.HRPolicyBenefit] },
            component: () => import("@/views/hr/policy/v2/ViewBenefit.vue"),
          },
          {
            path: "company-profile",
            name: hrRouteName("policy-company-profile"),
            meta: { permissions: [permissions.HRCompanyProfile] },
            component: () => import("@/views/hr/policy/CompanyProfile.vue"),
          },
        ],
      },
      {
        path: hrRoutePath("member"),
        name: hrRouteName("member"),
        meta: { permissions: [permissions.HRMembers] },
        redirect: () => {
          return { name: hrRouteName("member-listing") };
        },
        children: [
          {
            path: "listing",
            name: hrRouteName("member-listing"),
            meta: { permissions: [permissions.HRMembers] },
            component: () => import("@/views/hr/member/MemberListing.vue"),
          },
          {
            path: "terminate-member",
            name: hrRouteName("member-terminate"),
            meta: { permissions: [permissions.HRMembers] },
            component: () => import("@/views/hr/member/TerminateMember.vue"),
          },
          {
            path: "create-member",
            name: hrRouteName("create-member"),
            meta: { permissions: [permissions.HRMembers] },
            component: () => import("@/views/hr/member/CreateMember.vue"),
          },
          {
            path: "wallet-member",
            name: hrRouteName("wallet-member"),
            meta: { permissions: [permissions.HRMembers] },
            component: () => import("@/views/hr/member/WalletMember.vue"),
          },
          {
            path: "terminate-member-success",
            name: hrRouteName("member-terminate-success"),
            meta: { permissions: [permissions.HRMembers] },
            component: () =>
              import("@/views/hr/member/TerminateMemberSuccess.vue"),
          },
          {
            path: "profile/:memberId",
            name: hrRouteName("member-profile"),
            meta: { permissions: [permissions.HRMemberProfile] },
            component: () => import("@/views/hr/member/MemberProfile.vue"),
          },
          {
            path: "update-bank-info/:memberId",
            name: hrRouteName("member-update-bank-info"),
            meta: { permissions: [permissions.HRUpdateMemberBankInfo] },
            component: () => import("@/views/hr/member/UpdateBankInfo.vue"),
          },
          {
            path: "profile/edit/:memberId",
            name: hrRouteName("member-change-profile-detail"),
            meta: { permissions: [permissions.HRUpdateMemberProfile] },
            component: () =>
              import("@/views/hr/member/UpdateProfileDetail.vue"),
          },
          {
            path: "claim-history/:memberId",
            name: appRouteName("member-claim-history-by-member"),
            meta: { permissions: [permissions.HRClaimHistory] },
            component: () =>
              import("@/views/hr/member/ClaimHistoryByMember.vue"),
          },
          {
            path: "claim-detail/:claimHistoryId",
            name: appRouteName("member-claim-detail-member"),
            meta: { permissions: [permissions.HRDetail] },
            component: () => import("@/views/hr/member/ShowHistoryDetail.vue"),
          },
          {
            path: "view-benefit/:memberId",
            name: appRouteName("view-benefit-by-member"),
            meta: { permissions: [permissions.HRMemberPolicyBenefit] },
            component: () => import("@/views/hr/member/v2/ViewBenefit.vue"),
          },
        ],
      },
      {
        path: hrRoutePath("service"),
        name: hrRouteName("service"),
        // meta: { permissions: [permissions.HRMembers] },
        redirect: () => {
          return { name: hrRouteName("hr-service-request") };
        },
        children: [
          {
            path: "hr-service-request",
            name: hrRouteName("hr-service-request"),
            // meta: { permissions: [permissions.HRMembers] },
            component: () =>
              import("@/views/hr/service-request/ServiceListing.vue"),
          },
          {
            path: ":serviceRequestHistoryId/hr-service-request-detail",
            name: hrRouteName("hr-service-request-detail"),
            // meta: { permissions: [permissions.HRMembers] },
            component: () =>
              import("@/views/hr/service-request/ServiceListingDetail.vue"),
          },
        ],
      },
      {
        path: hrRoutePath("submit-claim"),
        name: hrRouteName("submit-claim"),
        meta: { permissions: [permissions.HRSubmitClaim] },
        redirect: () => {
          return {
            name: hrRouteName("submit-claim-reimbursement"),
          };
        },
        children: [
          {
            path: "form-reimbursement",
            name: hrRouteName("submit-claim-reimbursement"),
            meta: { permissions: [permissions.HRSubmitClaim] },
            component: () =>
              import("@/views/hr/submit-claim/HrReimbursement.vue"),
          },
          {
            path: "summary-before-submit",
            name: hrRouteName("submit-claim-summary-before-submit"),
            meta: { permissions: [permissions.HRSubmitClaim] },
            component: () =>
              import("@/views/hr/submit-claim/SummaryBeforeSubmit.vue"),
          },
          {
            path: ":claimId/form-reimbursement-edit",
            name: hrRouteName("submit-claim-reimbursement-edit"),
            meta: { permissions: [permissions.HRSubmitClaim] },
            component: () =>
              import("@/views/hr/submit-claim/HrReimbursement.vue"),
          },
        ],
      },
    ],
  },
  {
    path: prefix,
    component: () => import("@/layouts/hr-layout/AuthLayout.vue"),
    children: [
      {
        path: "sign-in",
        name: hrRouteName("sign-in"),
        meta: {},
        component: () => import("@/views/hr/auth/SignIn.vue"),
      },
      {
        path: "asking-otp",
        name: hrRouteName("asking-otp-email"),
        meta: {},
        component: () => import("@/views/hr/auth/AskingOTPEmail.vue"),
      },
      {
        path: "verify-otp",
        name: hrRouteName("verify-otp"),
        meta: {},
        component: () => import("@/views/hr/auth/VerifyOTPEmail.vue"),
      },
      {
        path: "verify-otp-email",
        name: hrRouteName("verify-otp-email"),
        meta: {},
        component: () => import("@/views/hr/auth/VerifyOTPEmail.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/hr-layout/Layout.vue"),
    meta: {
      system: "hr",
    },
    children: [
      {
        path: hrRoutePath("password-reset"),
        name: hrRouteName("password-reset"),
        component: () => import(`@/views/hr/auth/ForgetYourPassword.vue`),
      },
      {
        path: hrRoutePath("check-your-email"),
        name: hrRouteName("check-your-email"),
        component: () => import(`@/views/hr/auth/CheckYourEmail.vue`),
      },
      {
        path: hrRoutePath("set-your-new-password"),
        name: hrRouteName("set-your-new-password"),
        component: () => import(`@/views/hr/auth/SetYourNewPassword.vue`),
      },
    ],
  },
];

export default frontDeskRouteList;
