import { defineStore } from "pinia";
import { convertDateToISOString, getTranslate } from "@/core/helpers/custom";
import {
  CardTypeStringEnum,
  defaultComponent,
  InputDirective,
  InputTypeEnum,
} from "@/core/data/data";
import {
  IDateInput,
  IInput,
  IRadioInput,
  IRadioOption,
  ISelectInput,
} from "@/interface/IInput";
import { IUploadFileUniqueName } from "@/interface/imageUploadInterface";
import {
  FDBankInfoResponse,
  FDClaimDetailForEditResponse,
  MBBeneficiaryListResponseItem,
  MBBeneficiaryV2ListResponseItem,
  MBConfinedListResponseItem,
  MBDegreeBurnListResponseItem,
  MemberV2Api,
} from "@/api";
import { number, object, string } from "yup";
import * as Yup from "yup";

export const useMemberAccidentalBurnStore = defineStore({
  id: "memberAccidentalStore",
  state: () => ({
    uiData: {
      titles: {
        en: "ACCIDENTAL BURN",
        km: getTranslate("ACCIDENTAL BURN"),
      },
      formSection: {
        informationOfInsured: {
          titles: {
            en: "INFORMATION OF INSURED",
            km: getTranslate("INFORMATION OF INSURED"),
          },
          field: [
            <ISelectInput>{
              label: {
                en: "Name of Insured",
                km: getTranslate("Name of Insured"),
              },
              name: "nameOfInsured",
              value: 1,
              type: InputTypeEnum.Select,
              options: {},
              column: "col-12",
              formDataKey: "nameOfInsuredList",
            },
            <IInput>{
              label: {
                en: "Phone Number",
                km: getTranslate("Phone Number"),
              },
              name: "phoneNumber",
              value: "012480300",
              type: InputTypeEnum.PhoneInput,
              column: "col-12 col-md-6",
              placeholder: "Ex. 012456232",
              directives: [InputDirective.phoneValidateV2()],
            },
            <IInput>{
              label: {
                en: "Alternate Phone Number",
                km: getTranslate("Alternate Phone Number"),
              },
              name: "alternatePhoneNumber",
              value: "012480300",
              type: InputTypeEnum.PhoneInput,
              column: "col-12 col-md-6",
              placeholder: "Ex. 012456232",
              directives: [InputDirective.phoneValidateV2()],
            },
            <IInput>{
              label: {
                en: "Address",
                km: getTranslate("Address"),
              },
              name: "address",
              value: "Phnom Penh",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: Phnom Penh, Cambodia",
              directives: [InputDirective.allowMaxCharacter()],
            },
          ],
        },
        informationRelatedToBurn: {
          titles: {
            en: "INFORMATION RELATED TO BURN",
            km: getTranslate("INFORMATION RELATED TO BURN"),
          },
          field: [
            <IRadioInput>{
              label: {
                en: "Which degree of burn are you claiming for?",
                km: getTranslate("Which degree of burn are you claiming for?"),
              },
              name: "whichDegreeOfBurnAreYouClaimingFor",
              type: InputTypeEnum.Radio,
              options: <IRadioOption[]>[],
              column: "col-12",
              children: [],
              formDataKey: "degreeBurnRadioList",
            },
            <IInput>{
              label: {
                en: "Which part of your body was burned?",
                km: getTranslate("Which part of your body was burned?"),
              },
              name: "whichPartOfYourBodyWasBurned",
              value: "Right Leg",
              type: InputTypeEnum.Text,
              column: "col-12",
              placeholder: "Ex: Right Leg",
              directives: [InputDirective.allowMaxCharacter(50)],
            },
            <IInput>{
              label: {
                en: "Cause of accident",
                km: getTranslate("Cause of accident"),
              },
              name: "causeOfAccident",
              value: "Accident",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-6",
              placeholder: "Ex: Accident",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IDateInput>{
              label: {
                en: "Accident Date and Time",
                km: getTranslate("Accident Date and Time"),
              },
              name: "dateAndTimeOfAccident",
              value: convertDateToISOString(new Date("2020-01-01")),
              type: InputTypeEnum.Date,
              column: "col-12 col-md-6",
              placeholder: "Ex: 12 March 2022",
              forOption: 1,
              isShowTime: true,
            },
            <IInput>{
              label: {
                en: "Place of Accident",
                km: getTranslate("Place of Accident"),
              },
              name: "placeOfAccident",
              value: "My house",
              type: InputTypeEnum.Text,
              column: "col-12 col-md-12",
              placeholder: "Ex: My house",
              directives: [InputDirective.allowMaxCharacter(100)],
            },
            <IDateInput>{
              label: {
                en: "Date of First Consultation",
                km: getTranslate("Date of First Consultation"),
              },
              name: "dateOfFirstConsultation",
              value: "2011-10-05T14:48:00.000Z",
              type: InputTypeEnum.Date,
              column: "col-12",
              placeholder: "Ex. 12 March 2022",
            },
            defaultComponent.areYouCurrentlyConfinedAnyOfTheFollowing(),
          ],
        },
        uploadDocument: defaultComponent.uploadDocument(),
        paymentDetails: defaultComponent.paymentDetails(),
      },
      degreeBurnList: [] as MBDegreeBurnListResponseItem[],
      confinedList: [] as MBConfinedListResponseItem[],
      memberList: [] as MBBeneficiaryV2ListResponseItem[],
      formValue: {
        //list of field
        nameOfInsured: undefined as number | undefined,
        phoneNumber: undefined as string | undefined,
        alternatePhoneNumber: undefined as string | undefined,
        address: undefined as string | undefined,
        whichDegreeOfBurnAreYouClaimingFor: undefined as string | undefined,
        whichPartOfYourBodyWasBurned: undefined as string | undefined,
        causeOfAccident: undefined as string | undefined,
        dateAndTimeOfAccident: undefined as Date | undefined,
        placeOfAccident: undefined as string | undefined,
        dateOfFirstConsultation: undefined as Date | undefined,
        hospitalClinicAddress: undefined as string | undefined,
        nameOfDoctorHospitalClinic: undefined as string | undefined,
        areYouCurrentlyConfinedAnyOfTheFollowing: undefined as
          | string
          | undefined,
        degreeBurnRadioList: [] as IRadioOption[],
        confinedRadioList: [] as IRadioOption[],
        bankName: undefined as string | undefined,
        bankAccountNumber: undefined as string | undefined,
        bankAccountName: undefined as string | undefined,
        cardId: undefined as number | undefined,
        fileList: [] as IUploadFileUniqueName[],
        nameOfInsuredList: {},
        bankNameList: {},
        existedId: undefined as number | undefined,
      },
      alert: {
        title: {
          en: "Terms and conditions",
          km: getTranslate("Terms and conditions"),
        },
      },
    },
    existedId: undefined as number | undefined,
    existedData: undefined as FDClaimDetailForEditResponse | undefined,
    isInEditSession: false,
    cardDetail: {
      policyNumber: "",
      memberNumber: "",
      type: "",
      effectiveDate: undefined as string | undefined,
    },
    validateSchema: object().shape({
      nameOfInsured: number().required(),
      phoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Phone number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Phone number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .required({
          en: "Please enter phone number",
          km: "សូមបញ្ចូលលេខទូរស័ព្ទ",
        })
        .label("Phone Number"),
      alternatePhoneNumber: Yup.string()
        // only number is allowed
        .matches(/^[0-9]*$/, {
          message: {
            en: "Incorrect Phone Number Format. Please input again.",
            km: "លេខទូរស័ព្ទមានទម្រង់មិនត្រឹមត្រូវ។  សូមបញ្ចូលម្តងទៀត។",
          },
        })
        .min(9, {
          en: "Alt Phone Number must be at least 9 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ៩ ខ្ទង់",
        })
        .max(10, {
          en: "Alt Phone Number must be at most 10 digits",
          km: "ទូរស័ព្ទត្រូវមានតួអក្សរយ៉ាងហោចណាស់ ១០ ខ្ទង់",
        })
        .nullable()
        .label("Alt Phone Number"),
      address: string().max(100).required(),
      fileUpload: Yup.string()
        .matches(new RegExp("\\b" + "true" + "\\b"), {
          message: {
            en: "File upload is required.",
            km: "ឯកសារត្រូវបានទាមទារ",
          },
        })
        .required({
          en: "File upload is required.",
          km: "ឯកសារត្រូវបានទាមទារ",
        }),
      bankName: string().required(),
      whichDegreeOfBurnAreYouClaimingFor: string().required(),
      whichPartOfYourBodyWasBurned: string().max(50).required(),
      causeOfAccident: string().max(100).required(),
      dateAndTimeOfAccident: string().required(),
      placeOfAccident: string().max(100).required(),
      dateOfFirstConsultation: string().required(),
      areYouCurrentlyConfinedAnyOfTheFollowing: string().required(),
    }),
  }),
  getters: {
    getFileList: (state) => state.uiData.formValue.fileList,
    getCardId: (state) => state.uiData.formValue.cardId,
    getCurrentMember: (state) => {
      //get current member by current member id
      return state.uiData.memberList.find(
        (member) => member.id === state.uiData.formValue.nameOfInsured
      );
    },
    getCurrentDegreeBurn: (state) => {
      //get current degree burn by current degree burn id
      return state.uiData.degreeBurnList.find(
        (degreeBurn) =>
          degreeBurn.id ===
          Number(state.uiData.formValue.whichDegreeOfBurnAreYouClaimingFor)
      );
    },
    getCurrentConfined: (state) => {
      //get current confined by current confined id
      return state.uiData.confinedList.find(
        (confined) =>
          confined.id ===
          Number(
            state.uiData.formValue.areYouCurrentlyConfinedAnyOfTheFollowing
          )
      );
    },
    getCardDisplay: (state) => {
      if (state.cardDetail.type === CardTypeStringEnum.INDIVIDUAL) {
        return `POLICY NUMBER : ${state.cardDetail.policyNumber}`;
      } else {
        return `MEMBER NUMBER : ${state.cardDetail.memberNumber}`;
      }
    },
    isAnyFileUploaded(): boolean {
      return this.uiData.formValue.fileList.length > 0;
    },
  },
  actions: {
    updateUploadImage(data: IUploadFileUniqueName[]) {
      data.forEach((fileMeta) => {
        fileMeta.uniqueName = fileMeta.fileName;
        this.getFileList.push(fileMeta);
        // insert uniqueName to each data by using fileName
      });
    },
    removeImageItem(fileName: string) {
      const _filterList = this.getFileList.filter(
        (meta) => fileName !== (meta.uniqueName ?? meta.fileName)
      );
      this.setFileList(_filterList);
    },
    setFileList(data: IUploadFileUniqueName[]) {
      this.uiData.formValue.fileList = data;
    },
    async getMemberList() {
      await MemberV2Api.beneficiaries({ memberId: this.getCardId }).then(
        (data) => {
          this.setMemberList(data.items ?? []);
        }
      );
    },
    setMemberList(data: MBBeneficiaryListResponseItem[]) {
      this.uiData.memberList = data;
      this.pushValuesToNameOfInsured();
    },
    pushValuesToNameOfInsured() {
      this.uiData.formValue.nameOfInsuredList = this.uiData.memberList.reduce(
        (acc, cur) => {
          return { ...acc, [cur.id ?? ""]: cur.name };
        },
        {}
      ) as object;
    },
    async getBankInfo() {
      await MemberV2Api.bankInfo({ memberId: this.getCardId }).then((data) => {
        this.setBankInfo(data);
      });
    },
    setBankInfo(data: FDBankInfoResponse) {
      const _formValue = this.uiData.formValue;
      _formValue.bankName = data.bankName;
      _formValue.bankAccountNumber = data.bankAccountNumber;
      _formValue.bankAccountName = data.bankAccountName;
    },
    setCardId(id: number) {
      this.uiData.formValue.cardId = id;
    },
    async getDegreeBurnList() {
      await MemberV2Api.degreeBurn().then((data) => {
        this.setDegreeBurnList(data.data as MBDegreeBurnListResponseItem[]);
      });
    },
    setDegreeBurnList(data: MBDegreeBurnListResponseItem[]) {
      this.uiData.degreeBurnList = data;
      //convert degreeburnlist to object and push to degreeBurnSelectList
      this.uiData.formValue.degreeBurnRadioList =
        this.uiData.degreeBurnList.map((degreeBurn) => {
          return <IRadioOption>{
            label: {
              en: degreeBurn.name,
              km: degreeBurn.nameAlt,
            },
            value: degreeBurn.id,
          };
        });
    },
    async getConfinedList() {
      await MemberV2Api.confined().then((data) => {
        this.setConfinedList(data.data as MBConfinedListResponseItem[]);
      });
    },
    setConfinedList(data: MBConfinedListResponseItem[]) {
      this.uiData.confinedList = data;
      this.uiData.formValue.confinedRadioList = this.uiData.confinedList.map(
        (confined) => {
          return <IRadioOption>{
            label: {
              en: confined.name,
              km: confined.nameAlt,
            },
            value: confined.id,
          };
        }
      );
    },
    async loadExistingClaim() {
      await MemberV2Api.claimDetailForEdit({
        id: Number(this.existedId),
      }).then((data) => {
        this.existedData = data;
        this.setEditFormValue(data);
      });
      this.isInEditSession = true;
    },
    setEditFormValue(data: FDClaimDetailForEditResponse) {
      const _form = this.uiData.formValue;
      _form.existedId = this.existedId;
      _form.nameOfInsured = data.memberId;
      _form.phoneNumber = data.phoneNumber;
      _form.alternatePhoneNumber = data.alternativePhoneNumber;
      _form.address = data.address;
      _form.whichDegreeOfBurnAreYouClaimingFor = String(data.degreeBurn?.id);
      _form.whichPartOfYourBodyWasBurned = data.partBodyBurned;
      _form.causeOfAccident = data.causeOfAccident;
      _form.dateAndTimeOfAccident = data.dateOfEvent;
      _form.placeOfAccident = data.placeOfAccident;
      _form.dateOfFirstConsultation = data.dateFirstConsultation;
      _form.areYouCurrentlyConfinedAnyOfTheFollowing = String(
        data.confined?.id
      );
      _form.fileList = data.uploadedFiles ?? [];
    },
    async loadCardData() {
      await MemberV2Api.profile({ memberId: this.getCardId })
        .then((data) => {
          this.cardDetail = {
            memberNumber: data.policyProfile?.memberNumber ?? "",
            policyNumber: data.policyProfile?.policyNumber ?? "",
            type: data.policyProfile?.type ?? "",
            effectiveDate: data.policyProfile?.effectiveDate?.toString() ?? "",
          };
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
});
